import { IAction } from '../../types/generics';
import { IProgramFormState } from '../../types/programForm';
import { GET_PROGRAM_CONTEXT_LIST, GET_PROGRAM_CONTEXT_LIST_ERROR, GET_PROGRAM_CONTEXT_LIST_LOADING, GET_PROGRAM_FORM_CONTEXT_DATA, GET_PROGRAM_FORM_CONTEXT_DATA_ERROR, GET_PROGRAM_FORM_CONTEXT_CACHE, GET_PROGRAM_FORM_CONTEXT_DATA_LOADING, GET_PROGRAM_FORM_STRUCTURE, GET_PROGRAM_FORM_STRUCTURE_CACHE, GET_PROGRAM_FORM_STRUCTURE_ERROR, GET_PROGRAM_FORM_STRUCTURE_LOADING, RESET_PROGRAM_FORM_STRUCTURE, RESET_USER_FORM_DATA, OPEN_PROGRAM_FORM_MODAL, CLOSE_PROGRAM_FORM_MODAL } from '../actions/programFormAction';

const initState: IProgramFormState = {
    formStructureCache: [],
    userFormDataCache: [],
    formStructure: null,
    userformData: null,
    error: null,
    errorProgramData: null,
    loading: false,
    contextListLoading: false,
    contextListError: '',
    contextList: null,
    formModal: false
};

export default function (state = initState, action: IAction) {
    switch (action.type) {
        case GET_PROGRAM_FORM_STRUCTURE_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PROGRAM_FORM_STRUCTURE:
            return {
                ...state,
                formStructure: action.payload.formDefinition.formDefinition || action.payload.formDefinition,
                loading: false,
                error: null,
            };
        case GET_PROGRAM_FORM_STRUCTURE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case GET_PROGRAM_FORM_STRUCTURE_CACHE:
            const updatedCache = Array.isArray(state.formStructureCache)
                ? [...state.formStructureCache]
                : [];
            const newItem = action.payload?.formDefinition?.formDefinition || action.payload?.formDefinition || {};
            const cacheWithoutDuplicates = updatedCache.filter(item => item.details.id !== newItem.details.id);
            return {
                ...state,
                formStructureCache: [...cacheWithoutDuplicates, newItem],
                loading: false,
                error: null,
            };

        case GET_PROGRAM_FORM_CONTEXT_DATA_LOADING:
            return {
                ...state,
                loading: true,
                errorProgramData: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_DATA:
            return {
                ...state,
                userformData: action.payload,
                loading: false,
                errorProgramData: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_CACHE:
            const updatedFormContextCache = Array.isArray(state.userFormDataCache)
                ? [...state.formStructureCache]
                : [];
            return {
                ...state,
                userFormDataCache: [...updatedFormContextCache, action.payload],
                loading: false,
                errorProgramData: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_DATA_ERROR:
            return {
                ...state,
                loading: false,
                errorProgramData: action.payload,
            };
        case GET_PROGRAM_CONTEXT_LIST_LOADING:
            return {
                ...state,
                contextListLoading: true,
                contextListError: null,
            };
        case GET_PROGRAM_CONTEXT_LIST:
            return {
                ...state,
                contextList: action.payload,
                contextListLoading: false,
                contextListError: null,
            };
        case GET_PROGRAM_CONTEXT_LIST_ERROR:
            return {
                ...state,
                contextListLoading: false,
                contextListError: action.payload,
            };
        case RESET_USER_FORM_DATA:
            return {
                ...state,
                userformData: null
            }
        case RESET_PROGRAM_FORM_STRUCTURE:
            return {
                ...state,
                formStructure: null
            }

        case OPEN_PROGRAM_FORM_MODAL:
            return {
                ...state,
                formModal: true
            }

        case CLOSE_PROGRAM_FORM_MODAL:
            return {
                ...state,
                formModal: false
            }

        default:
            return state;
    }
}
