import React from 'react';
import { AppBar, Toolbar, Typography, LinearProgress } from '@material-ui/core';
import { useStyles } from '../../style';
import logoImage from '../../../../../assets/logo.png'

function HeaderWithProgressBar({ logoText = "Logo", progressValue = 40 }) {
    const classes = useStyles();
    const logOut = () => {
        localStorage.clear();
        window.location.href = '/login';
      }
    return (
        <div>
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar className={classes.header}>
                <img 
                src={logoImage}
                alt="Logo"
                className={classes.logo}
            />

                    <div className={classes.logoutButton} onClick={logOut}>
                    <button className='btn nextBtn'>Logout</button>
                    </div>
                </Toolbar>
            </AppBar>
            <LinearProgress variant="determinate" value={progressValue} className={classes.progressBar} />
 
        </div>
    );
}

export default HeaderWithProgressBar;
