import "./style.scss";
import * as React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { handleDrawer } from '../../../redux/actions/drawerAction';
import { useStyles } from './style';
import { RootState } from '../../../redux/store';
import { IUser } from '../../../types/user';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import DashboardIcon from '../../../components/Icons/DashboardIcon';
import BackpackIcon from '../../../components/Icons/BackpackIcon';
import SettingIcon from '../../../components/Icons/SettingIcon';
import HelpIcon from '../../../components/Icons/HelpIcon';
import ChatIcon from '../../../components/Icons/ChatIcon';
import HamburgerIcon from '../../../components/Icons/HamburgerIcon';
import { getProgramMyBackpackData } from '../../../redux/actions/programAction';
import { IProgramBackpackFormInfo } from '../../../types/program';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { isAdmin } from '../../../util/helpers';
import { getTimeBasedGreeting } from '../../../util/DateTimeUtil';
import { staticActiveProgramEnrollment } from '../../../constants/data';
import { useSocket } from '../../../provider/socketProvider';
import logoImage from '../../../assets/logo.png'


interface PropsType {
  isOpen: boolean;
  user: IUser;
  loader: string;
  handleDrawer: (isOpen: boolean) => any;
}

function ResponsiveDrawer(props: PropsType) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const [settingOpen, setSettingOpen] = React.useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openBackpack, setOpenBackpack] = React.useState(false);
  const [openPockets, setOpenPockets] = React.useState<{ [key: string]: boolean }>({});
  const { backpackList }: any = useSelector((state: RootState) => state.programReducer);
  const settingItems = ["Profile", "General", "Payment", "Subscription", 'Log Out'];
  const userdId = localStorage.getItem("user");
  const { user } = useSelector((state: RootState) => state.auth);
  const { programId } = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
  const { channels,lastChatRoom} = useSelector((state: RootState) => state.appChatReducer);
  const { joinRoom } = useSocket();


  const handleClick = () => {
    setOpen(!open);
  };

  const handleBackpackClick = React.useCallback(() => {
    setOpenBackpack(!openBackpack);
  }, [openBackpack]);

  const isPathBasedItem = (item: any): item is { path: string; label: any } => {
    return item && typeof item.path === 'string';
  };

  const handlePocketClick = (pocketKey: string) => {
    setOpenPockets(prev => ({
      ...prev,
      [pocketKey]: !prev[pocketKey],
    }));
  };

  const handleSetting = () => {
    setSettingOpen(!settingOpen);
  }

  const logOut = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  const toggleDrawer = () => () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    if (!isMobile) {
      setDrawerOpen(false);
    }
  }, [isMobile]);

  const isActive = (path: string) => {
    const { pathname, search } = location;
    return (
      pathname === path || (pathname.startsWith(path) && search === new URLSearchParams(path).toString())
    );
  };

  const handleNavigate = (path) => {
    history.push(path);
    setDrawerOpen(false);
  }

  React.useEffect(() => {
    dispatch(getProgramMyBackpackData(programId, userdId));
    joinRoom(userdId)

  }, []);
  React.useEffect(() => {
    joinRoom(userdId)
  }, [userdId]);

  const sideBarItems = [
    {
      type: 'item',
      path: '/user/dashboard',
      label: 'Dashboard',
      icon: <DashboardIcon />,
      onClick: () => handleNavigate('/user/dashboard')
    },
    {
      type: 'nested_collapse',
      label: 'My Backpack',
      icon: <BackpackIcon />,
      isOpen: openBackpack,
      onClick: handleBackpackClick,
      nestedItems: backpackList &&
        backpackList?.pockets.length > 0 &&
        Array.isArray(backpackList?.pockets)
        ? backpackList.pockets.map((pocket: any) => ({
          name: pocket.name,
          forms: Array.isArray(pocket.forms)
            ? pocket.forms.map((form: IProgramBackpackFormInfo) => {
              const contextIdParam = form.contextId ? `&contextId=${form.contextId}` : '';
              return {
                path: `/user/form/show?sourceFormId=${form.sourceFormId}&formId=${form._id}&userId=${user?._id}${contextIdParam}`,
                label: form.label,
              }
            })
            : [],
        }))
        : [],
    },
    {
      type: 'item',
      path: '/user/chat',
      label: 'Chat Hub',
      icon: <ChatIcon />,
      onClick: () => {
        const roomId = lastChatRoom?.room || channels[0]?.['room_id']?._id || 'GENERAL';
        const roomType = lastChatRoom?.roomType === 'direct' ? 'direct' : 'channel';
        handleNavigate(`/user/chat?roomId=${roomId}&type=${roomType}`);
        joinRoom(roomId);

      }
    },
    {
      type: 'collapse',
      label: 'Settings',
      icon: <SettingIcon />,
      isOpen: settingOpen,
      onClick: handleSetting,
      nestedItems: settingItems.map((item: any) => ({
        path: `/user/${item.toLowerCase()}`,
        label: item,
      }))
    },
    {
      type: 'item',
      path: '/user/support',
      label: 'Support',
      icon: <HelpIcon />,
      onClick: () => handleNavigate('/user/support')
    },
    {
      type: 'item',
      onClick: () => logOut(),
      label: 'Sign Out',
      icon: <ExitToAppIcon style={{ width: '24px', height: '21px' }} />,
    }
  ];

  if (isAdmin()) {
    sideBarItems.unshift({
      type: 'item',
      path: '/admin/dashboard',
      label: 'Admin Console',
      icon: <DashboardIcon />,
      onClick: () => handleNavigate('/admin/dashboard')
    });
  }
  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer()}
          className={classes.hamburgerButton}
        >
          <HamburgerIcon />
        </IconButton>
      )}
      <Drawer
        className={`${classes.drawer} sidebar_Wrapper`}
        variant={isMobile ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={toggleDrawer()}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <List className={classes.list} >
          <ListItem style={{ justifyContent: 'center' }}>
            <Link to='/' className={classes.logo}>
              <div className={classes.logoWrapper}>
                <img
                  src={logoImage}
                  alt="Logo"
                  className={classes.logoImage}
                />
              </div>
            </Link>
          </ListItem>
          <ListItem>
            <Avatar
              alt={user?.name}
              className={classes.avatar}
              src={process.env.REACT_APP_API_URL + '/' + user?.avatarUrl}
            />
            <div>
              <span className={classes.grey}>{getTimeBasedGreeting()}!</span>
              <div className={classes.profileName}>{user && (user?.name + ' ' + user?.lastName)}</div>
            </div>
          </ListItem>
          <ListItem className={classes.search}>
            <SearchIcon />
            <InputBase
              className={classes.input}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              fullWidth
            />
          </ListItem>

          {sideBarItems.map((item, index) => {


            switch (item.type) {
              case 'item':
                return (
                  <ListItem
                    key={index}
                    className={`${classes.listItem} ${isActive(item.path) ? 'active' : ""}`}
                    button
                    onClick={item.onClick}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText className={`${classes.listItemText}`}
                      secondary={item.label} />
                  </ListItem>
                );
              case 'nested_collapse':
                const groupedPockets = item.nestedItems.reduce((acc: any, pocket: any) => {
                  const [mainPocket, subGrouping] = pocket.name.includes('|') ? pocket.name.split('|') : [pocket.name, null];

                  if (!acc[mainPocket]) {
                    acc[mainPocket] = { forms: [], subGroups: [] };
                  }

                  if (subGrouping) {
                    acc[mainPocket].subGroups.push({ subGrouping, forms: pocket.forms });
                  } else {
                    acc[mainPocket].forms = pocket.forms;
                  }

                  return acc;
                }, {});

                return (
                  <React.Fragment key={index}>
                    <ListItem button onClick={item.onClick}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText className={`${classes.listItemText}`} secondary={item.label} />
                      {item.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {Object.entries(groupedPockets).map(([mainPocket, pocketData]: any, pocketIndex) => {
                          const uniqueKey = `${mainPocket}-${pocketIndex}`;

                          return (
                            <React.Fragment key={uniqueKey}>

                              {/* main pcoket */}
                              <ListItem button className={`${classes.listItem}`} onClick={() => handlePocketClick(uniqueKey)}>
                                <ListItemText secondary={mainPocket} />
                                {openPockets[uniqueKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </ListItem>

                              <Collapse in={openPockets[uniqueKey]} timeout="auto" unmountOnExit>
                                {/* sub grouping */}

                                <List component="div" disablePadding>
                                  {pocketData?.forms?.map((nestedItem: any, nestedIndex: number) => (
                                    <ListItem
                                      className={classes.nested}
                                      key={`${uniqueKey}-form-${nestedIndex}`}
                                      button
                                      onClick={() => handleNavigate(nestedItem.path)}
                                    >
                                      <ListItemText secondary={nestedItem.label} />
                                    </ListItem>
                                  ))}
                                </List>

                                {pocketData?.subGroups?.map((subGroup: any, subGroupIndex: number) => {
                                  const subGroupKey = `${uniqueKey}-sub-${subGroupIndex}`;
                                  return (
                                    <React.Fragment key={subGroupKey}>
                                      <ListItem button className={`${classes.listItem} ${classes.nested}`} onClick={() => handlePocketClick(subGroupKey)}>
                                        <ListItemText secondary={subGroup.subGrouping} />
                                        {openPockets[`${subGroupKey}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                      </ListItem>
                                      <Collapse in={openPockets[subGroupKey]} timeout="auto" unmountOnExit>
                                        {/* forms */}
                                        <List component="div" disablePadding>
                                          {subGroup.forms.map((form: any, formIndex: number) => (
                                            <ListItem
                                              className={classes.innernested}
                                              key={`${subGroupKey}-form-${formIndex}`}
                                              button
                                              onClick={() => handleNavigate(form.path)}
                                            >
                                              <ListItemText secondary={form.label} />
                                            </ListItem>
                                          ))}
                                        </List>
                                      </Collapse>
                                    </React.Fragment>
                                  );
                                })}
                              </Collapse>
                            </React.Fragment>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              case 'collapse':
                return (
                  <React.Fragment key={index}>
                    <ListItem button onClick={item.onClick}>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText className={`${classes.listItemText}`} secondary={item.label} />
                      {item.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItem>
                    <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item?.nestedItems && item?.nestedItems.length > 0 && item?.nestedItems?.map((nestedItem, nestedIndex) => (
                          isPathBasedItem(nestedItem) ? (
                            <ListItem
                              key={nestedIndex}
                              button
                              className={`${classes.nested} ${isActive(nestedItem.path) ? 'active' : ""}`}
                              onClick={() => handleNavigate(nestedItem.path)}
                            >
                              <ListItemText secondary={nestedItem.label} />
                            </ListItem>
                          ) : null
                        ))}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              default:
                return null;
            }
          })}
        </List>
      </Drawer>
    </>
  );
}

// ResponsiveDrawer.propTypes = {
//   container: PropTypes.instanceOf(
//     typeof Element === "undefined" ? Object : Element
//   )
// };

const mapStateToProps = (store: RootState) => ({
  user: store.auth.user,
  isOpen: store.drawerReducer.isOpen,
  loader: store.drawerReducer.loader,
});

export default connect(mapStateToProps, {
  handleDrawer,
})(ResponsiveDrawer);
