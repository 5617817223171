import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  TableBody,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import { DeleteOutline, Edit } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import { useParams } from 'react-router';
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProgramAnnouncementData,
  removeProgramAnnouncementData,
  saveAnnounceMent,
  updateAnnounceMent,
} from '../../../../redux/actions/programAction';
import { RootState } from '../../../../redux/store';
import moment from 'moment';
import AnnouncementAction from './components/AnnouncementAction';
import confirm from '../../../../components/ConfirmDialog';

const AnnouncementList = () => {
  const { programId }: any = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const currentUser = localStorage.getItem('user');
  const announcement = useSelector((state: RootState) => state.programReducer.announcementList);

  useEffect(() => {
    if (programId) {
      dispatch(getProgramAnnouncementData(programId, currentUser));
    }
  }, [programId, dispatch, currentUser]);

  const handlePageChange = (event, page) => {
    event.stopPropagation();
    setCurrentPage(page);
  };

  const handleDelete = (e, announcement) => {
    confirm('Are you Sure!', 'Are you sure you want to delete the Program Announcement?', { formAlert: true }, { modaltype: 'deleteAlert' }).then(
      (result) => {
        if (result === 'leaveAndDiscard') {
          return;
        }
        dispatch(removeProgramAnnouncementData(announcement));
      }
    );
  };

  const onEditAnnouncement = (data) => {
    setOpenModal(true);
    setEditData(data);
  };

  const onAddAnnounceMent = () => {
    setOpenModal(true);
  };

  const handleSave = useCallback(
    async (data) => {
      data.programId = programId;
      data._id ? dispatch(updateAnnounceMent(data)) : dispatch(saveAnnounceMent(data));
      setOpenModal(false);
    },
    [programId, dispatch]
  );

  // Paginate the announcements
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedAnnouncements = announcement?.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  return (
    <div>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell>Body</TableCell>
              <TableCell>Last Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedAnnouncements?.map((post, index) => (
              <TableRow key={index}>
                <TableCell>{post?.label}</TableCell>
                <TableCell>{post?.body}</TableCell>
                <TableCell>{moment(post?.updatedAt).format('MMM DD, YYYY')}</TableCell>
                <TableCell>
                  <IconButton onClick={() => onEditAnnouncement(post)}>
                    <Edit fontSize="small" style={{ color: '#333333' }} />
                  </IconButton>
                  <IconButton onClick={(e) => handleDelete(e, post)}>
                    <DeleteOutline fontSize="small" color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {paginatedAnnouncements?.length === 0 && (
              <TableRow>
                <TableCell align="center" colSpan={4}>
                  No records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* Pagination */}
        <Box display="flex" justifyContent="space-between" marginTop={2} marginBottom={2} paddingX={2}>
          <Button className={classes.addFormBtn} onClick={onAddAnnounceMent}>
            Add Announcement
          </Button>
          <Pagination
            className={classes.pagination}
            count={Math.ceil(announcement?.length / ITEMS_PER_PAGE)}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>
      </TableContainer>
      <AnnouncementAction
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setEditData(null);
        }}
        data={editData}
        onSave={handleSave}
      />
    </div>
  );
};

export default AnnouncementList;
