import React, { useEffect, useRef, useState } from 'react'
import { FormRenderer } from '../../../../User/Backpack'
import { Dialog } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useStyles } from '../../../../User/Backpack/style';
import { getProgramFormContextData, getProgramFormStructure } from '../../../../../redux/actions/programFormAction';
import CustomDialogTitle from '../../../../Admin/ProgramList/ProgramDrillDown/components/DialogTitle';
import confirm from "../../../../../components/ConfirmDialog";
import CircularProgress from '@material-ui/core/CircularProgress';

interface PropsType {
  isModal: boolean;
  closeModal: () => any;
  sourceFormId: string;
  userId: string;
  contextId: string;
  programId: string;
  year?: number
}

const ActionItemFormModal = ({ isModal, closeModal, sourceFormId, userId, contextId, programId, year }: PropsType) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { formStructure, error, userformData } = useSelector((state: RootState) => state.programFormReducer)
  const [cacheFormStructure, setCacheFormStructure] = useState(formStructure);
  const formRendererRef = useRef<{ handleSubmit: (event: React.FormEvent, data?: string) => void }>(null);
  const formState = useSelector((state: RootState) => state.programFormReducer)
  const loading=useSelector((state: RootState) => state.programFormReducer.loading)
  
  useEffect(() => {
    if (userId && sourceFormId) {
      let formStructureCache = formState.formStructureCache.filter((data) => ((data.details.programId === programId) && (data.details.sourceFormId === sourceFormId)))
      if (formStructureCache.length > 0) {
        setCacheFormStructure(formStructureCache[0])
      }
      // dispatch(getProgramFormStructure(programId, sourceFormId, userId));
      // dispatch(getProgramFormContextData(programId, sourceFormId, userId, contextId));
      return;
    }
  }, [isModal,formStructure]);
  const skipForm=(e)=>{
     let skip=(JSON.stringify({skipped:true }))    
     formRendererRef?.current?.handleSubmit(e,skip);
    //closeModal()
  }

  const closeFormModal = (e) => {
    if (!cacheFormStructure) {
      closeModal()
    }
    else {
      confirm("You have unsaved changes", "There are unsaved changes.You can save your changes,cancel to continue editing,or leave and discard changes.", { formAlert: true }).then((result) => {
        if (result === 'leaveAndDiscard') {
          formRendererRef?.current?.handleSubmit(e, result);
        }
        else {
          formRendererRef?.current?.handleSubmit(e);
        }
        closeModal()
      })
    }

  }
  const requireQuestions = cacheFormStructure ? Object.values(cacheFormStructure?.questions)?.filter((data: any) => data?.required === 'Yes' && data?.hidden !== 'Yes').length > 0 : false;  
  return (
    <>
    {(loading && isModal)? (<><div className={classes.onboardingSpinnerLoader}><CircularProgress  size={40} /></div></>):
      <Dialog
        open={isModal}
        onClose={closeFormModal}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <CustomDialogTitle onClose={(e) => closeFormModal(e)}>
          <div className={classes.formSkipWrapper}>
          <span>{cacheFormStructure?.details?.title}</span>

            <button
              className={`${requireQuestions ? classes.disable : "btn btn-primary"} ${classes.btnSkip}`}
              disabled={requireQuestions}
              onClick={skipForm}
            >
              Skip this step
            </button>
          </div>
        </CustomDialogTitle>
        {cacheFormStructure ? (
          <>

            <FormRenderer
              ref={formRendererRef}
              formResult={userformData}
              formDetail={cacheFormStructure}
              sourceFormId={sourceFormId}
              userId={userId}
              contextId={contextId}
              programId={programId}
              isOnboarding={true}
              year={year}
              openModal={isModal}
              onCloseModal={closeModal}
              requiredQuestions={requireQuestions}
            />
          </>
        ) : (
          error && error !== null && (
            <div className={classes.noDataFound}>No Form Found</div>
          )
        )}
      </Dialog>

      }
    </>
  );

}

export default ActionItemFormModal