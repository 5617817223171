import React, { useEffect, useState } from 'react';
import { Edit } from '@material-ui/icons';
import DeleteOutline from '@material-ui/icons/DeleteOutline';

const AddRowForm: React.FC<any> = ({
  colsArray,
  error,
  classes,
  commonProps,
  FormGroup,
  FormGroupInner,
  InputField,
  parentformData,
  onFormDataChange,
  name,
}) => {
  const [formRows, setFormRows] = useState<number[]>(parentformData[name]?.length > 0 ? parentformData[name] : [0]);
  const [formData, setFormData] = useState<{ [key: string]: any }>(parentformData[name]?.length > 0 ? { [name]: parentformData[name] } : { [name]: [] });
  const [saveButton, setSaveButton] = useState<number | null>(null);
  const addRows = () => {
    setFormRows((prevRows) => [...prevRows, prevRows.length]);
    setFormData((prevData) => ({
      ...prevData,
      [name]: [
        ...prevData[name],
        colsArray.reduce((acc, col) => ({ ...acc, [col.trim()]: '' }), {}),
      ],
    }));
  };

  const handleEditRow = (index: number) => {
    setSaveButton(index);
  };

  const handleDeleteRow = (rowIndex: number) => {
    setFormRows((prevRows) => prevRows.filter((_, idx) => idx !== rowIndex));
    setFormData((prevData) => ({
      ...prevData,
      [name]: prevData[name].filter((_, idx) => idx !== rowIndex),
    }));
  };

  const handleInputChange = (rowIndex: number, colKey: string, value: string) => {
    setFormData((prevData) => {
      const updatedRows = [...prevData[name]];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        [colKey]: value,
      };
      return { ...prevData, [name]: updatedRows };
    });
  };

  useEffect(() => {
    onFormDataChange({ ...parentformData, ...formData });
  }, [formData]);

  useEffect(() => {
    if (formRows.length > formData[name].length) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [
          ...prevData[name],
          ...Array(formRows.length - prevData[name].length).fill(
            colsArray.reduce((acc, col) => ({ ...acc, [col.trim()]: '' }), {})
          ),
        ],
      }));
    }
  }, [formRows, colsArray]);

  return (
    <>
      {formRows.map((_, rowIndex) => (
        <tr key={rowIndex}>
          {colsArray.map((col, colIndex) => {
            const colKey = col.trim();
            const cellValue = formData[name]?.[rowIndex]?.[colKey] || ''; // Safely access value
            return (
              <td key={`${rowIndex}-${colIndex}`}>
                <FormGroup error={error} classes={classes}>
                  <FormGroupInner classes={classes}>
                    <InputField
                      {...commonProps}
                      type="text"
                      id={`${col}-${rowIndex}-${colIndex}`}
                      name={`${col}-${rowIndex}-${colIndex}`}
                      value={cellValue}
                      onChange={(e) =>
                        handleInputChange(rowIndex, colKey, e.target.value)
                      }
                    />
                  </FormGroupInner>
                </FormGroup>
              </td>
            );
          })}
          <td>
            {saveButton === rowIndex ? (
              <button
                className={`${classes.btnStyle} btn`}
                onClick={() => setSaveButton(null)}
                type="button"
              >
                Save
              </button>
            ) : formRows.length === 1 || rowIndex === formRows.length - 1 ? (
              <button
                className={`${classes.btnStyle} btn`}
                onClick={addRows}
                type="button"
              >
                +
              </button>
            ) : (
              <div
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginBottom: '25px',
                  paddingLeft: '10px',
                }}
              >
                <Edit
                  fontSize="small"
                  style={{ color: '#333333', cursor: 'pointer' }}
                  onClick={() => handleEditRow(rowIndex)}
                />
                <DeleteOutline
                  fontSize="small"
                  color="error"
                  onClick={() => handleDeleteRow(rowIndex)}
                  style={{ padding: '5px 10px', cursor: 'pointer' }}
                />
              </div>
            )}
          </td>
        </tr>
      ))}
    </>
  );
};

export default AddRowForm;
