import { getProgramEnrollmentUserApi } from "../../api";
export const GET_USER_PROGRAM_ENROLLMENT = 'GET_USER_PROGRAM_ENROLLMENT';
export const GET_USER_PROGRAM_ENROLLMENT_ERROR = 'GET_USER_PROGRAM_ENROLLMENT_ERROR';
export const GET_USER_PROGRAM_ENROLLMENT_LOADING = 'GET_USER_PROGRAM_ENROLLMENT_LOADING';
export const SET_ACTIVE_PHASE_NUMBER = "SET_ACTIVE_PHASE_NUMBER";
export const CLOSE_USER_PROGRAM_ENROLLMENT_LOADING = "CLOSE_USER_PROGRAM_ENROLLMENT_LOADING";
export const PROGRAM_INFO_POPUP_OPENED='PROGRAM_INFO_POPUP_OPENED'

export const setProgramInfoPopup=()=>({
    type:PROGRAM_INFO_POPUP_OPENED
})

export const closeEnrollmentLoading = () => ({
    type: CLOSE_USER_PROGRAM_ENROLLMENT_LOADING
})

export const getUserProgramEnrollmentData = (programId: string, userId: string) => {
    return async (dispatch: any) => {
        dispatch(getprogramEnrollmentLoading());
        try {
            const res: any = await getProgramEnrollmentUserApi(programId, userId);
            dispatch(getprogramEnrollmentSuccess(res));
        } catch (error) {
            if (error.response) {
                dispatch(getprogramEnrollmentError(error.response.data.message || 'An error occurred'));
                return;
            }
            dispatch(getprogramEnrollmentError(error.message || 'An error occurred'));
        }
    };
};

export const getprogramEnrollmentLoading = () => ({
    type: GET_USER_PROGRAM_ENROLLMENT_LOADING,
});

const getprogramEnrollmentSuccess = (payload) => ({
    type: GET_USER_PROGRAM_ENROLLMENT,
    payload,
});

const getprogramEnrollmentError = (error: any) => ({
    type: GET_USER_PROGRAM_ENROLLMENT_ERROR,
    payload: error,
});

export const setActivePhaseNumber = (payload) => ({
    type: SET_ACTIVE_PHASE_NUMBER,
    payload
})