import toastr from 'toastr';
import { createAppChat, getChannelInfoMEOS, getDMMessagesMEOS, getJoinedChannelsListMEOS, sendDMMessageMEOS, getChannelMembersInfoMEOS, getUserApi, getPrivateMessageApi, getChannelMembers, getLatestChatUsers, editDMMessageMEOS, deleteDMMessageMEOS } from '../../api';
import { ChatRoom } from '../../types/chat';

export const GET_USERS_LIST_ERROR_MEOS = 'GET_USERS_LIST_ERROR_MEOS';
export const GET_ROOM_DATA_MEOS = "GET_ROOM_DATA_MEOS";
export const GET_MESSAGES_MEOS = 'GET_MESSAGES_MEOS';
export const GET_MESSAGES_ERROR_MEOS = 'GET_MESSAGES_ERROR_MEOS';
export const SEND_MESSAGE_MEOS = 'SEND_MESSAGE_MEOS';
export const DELETE_MESSAGE_MEOS = 'DELETE_MESSAGE_MEOS';
export const SEND_DIRECT_MESSAGE_MEOS = 'SEND_DIRECT_MESSAGE_MEOS';
export const DELETE_DIRECT_MESSAGE_MEOS = 'DELETE_DIRECT_MESSAGE_MEOS';
export const UPDATE_MESSAGE_MEOS = "UPDATE_MESSAGE_MEOS";
export const GET_JOINED_CHANNELS_LIST_MEOS = 'GET_JOINED_CHANNELS_LIST_MEOS';
export const GET_JOINED_CHANNELS_LIST_ERROR_MEOS = 'GET_JOINED_CHANNELS_LIST_ERROR_MEOS';
export const CREATE_CHANNEL_MEOS = 'CREATE_CHANNEL_MEOS';
export const CREATE_CHANNEL_MEOS_LOADING = "CREATE_CHANNEL_MEOS_LOADING";
export const CREATE_CHANNEL_MEOS_ERROR = "CREATE_CHANNEL_MEOS_ERROR";
export const GET_AUTOCOMPLETE_USERS_LIST_MEOS = 'GET_AUTOCOMPLETE_USERS_LIST_MEOS';
export const GET_AUTOCOMPLETE_USERS_LIST_ERROR_MEOS = 'GET_AUTOCOMPLETE_USERS_LIST_ERROR_MEOS';
export const GET_CHAT_USERS_INFO_MEOS = 'GET_CHAT_USERS_INFO_MEOS';
export const GET_CHAT_USERS_INFO_ERROR_MEOS = 'GET_CHAT_USERS_INFO_ERROR_MEOS';
export const GET_CHANNEL_INFO_MEOS = 'GET_CHANNEL_INFO_MEOS';
export const GET_CHANNEL_INFO_ERROR_MEOS = 'GET_CHANNEL_INFO_ERROR_MEOS';
export const UPLOAD_FILE_TO_ROOM_MEOS = 'UPLOAD_FILE_TO_ROOM_MEOS';
export const UPLOAD_FILE_TO_ROOM_ERROR_MEOS = 'UPLOAD_FILE_TO_ROOM_ERROR_MEOS';
export const SET_IS_EDIT_MESSAGE_MEOS = "SET_IS_EDIT_MESSAGE_MEOS";
export const SET_ENTERED_TEXT_MEOS = "SET_ENTERED_TEXT_MEOS";
export const CANCEL_IS_EDIT_MESSAGE_MEOS = 'CANCEL_IS_EDIT_MESSAGE_MEOS';
export const SET_MESSAGE_ID_MEOS = 'SET_MESSAGE_ID_MEOS';
export const GET_USER_PROFILE_INFO_MEOS = "GET_USER_PROFILE_INFO_MEOS";
export const GET_USER_PROFILE_INFO_ERROR_MEOS = "GET_USER_PROFILE_INFO_ERROR_MEOS";
export const UPDATE_MESSAGE_LIST_SO_MEOS = "UPDATE_MESSAGE_LIST_SO_MEOS";
export const CREATE_DM_MEOS = 'CREATE_DM_MEOS';
export const CREATE_DM_MEOS_LOADING = 'CREATE_DM_MEOS_LOADING';
export const CREATE_DM_MEOS_ERROR = 'CREATE_DM_MEOS_ERROR';
export const CHAT_MEMBERS = 'CHAT_MEMBERS'
export const GET_CHANNEL_MEMBER_INFO_ERROR = 'GET_CHANNEL_MEMBER_INFO_ERROR';
export const GET_PRIVATE_CHAT_INFO_MEOS = 'GET_PRIVATE_CHAT_INFO_MEOS';
export const GET_PRIVATE_CHAT_INFO_ERROR_MEOS = 'GET_PRIVATE_CHAT_INFO_ERROR_MEOS'
export const GET_ACTIVE_MESSAGE = 'GET_ACTIVE_MESSAGE'
export const SET_ACTIVE_MESSAGE = 'SET_ACTIVE_MESSAGE'
export const CHANNEL_MEMBERS = 'CHANNEL_MEMBERS'
export const GET_LATEST_CHAT_USER = 'GET_LATEST_CHAT_USER'
export const SET_EDIT_MESSAGE = 'SET_EDIT_MESSAGE'
export const LAST_CHAT_ROOM = 'LAST_CHAT_ROOM'


export const createAppChannel = (body: any) => {
    return async (dispatch: any) => {
        dispatch({ type: CREATE_CHANNEL_MEOS_LOADING });
        try {
            const res = await createAppChat(body);
            if (res) {
                dispatch({
                    type: CREATE_CHANNEL_MEOS,
                    payload: res,
                });
                toastr.success("Channel Created Successfully");
            }

        } catch (error) {
            dispatch({
                type: CREATE_CHANNEL_MEOS_ERROR,
                payload: error.message
            });
        }
    };
};

export const getActiveMessage = (messages: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: GET_ACTIVE_MESSAGE,
            payload: messages,
        });
    }
}
export const setActiveMessage = (messages: any) => {
    return async (dispatch: any) => {
        dispatch({
            type: SET_ACTIVE_MESSAGE,
            payload: messages,
        });
    }
}
export const getChatMessagesMEOS = (roomId: string, offset: number = 0, count: number = 50) => {
    return async (dispatch: any) => {
        try {
            let endPoint: string;

            const res: any = await getDMMessagesMEOS({ room_id: roomId });
            if (res) {
                dispatch({
                    type: GET_MESSAGES_MEOS,
                    payload: res.slice().reverse()
                });
            }
        } catch (error) {
            dispatch({
                type: GET_MESSAGES_ERROR_MEOS,
                payload: error?.errorType,
            });
        }
    };
};


export const getLastChatUsers = (userId: string, offset: number = 0, count: number = 50) => {
    return async (dispatch: any) => {
        try {
            const res: any = await getLatestChatUsers(userId);
            if (res) {
                dispatch({
                    type: GET_LATEST_CHAT_USER,
                    payload: res
                });
            }
        } catch (error) {
            console.log('error', error)
        }
    };
};



export const sendDmMessageMEOS = (body: any) => {
    return async (dispatch: any) => {
        try {
            const response: any = await sendDMMessageMEOS(body);
            if (body['receiverId']) {

                console.log("Pbodudfifdi", body)
                dispatch({
                    type: SEND_DIRECT_MESSAGE_MEOS,
                    payload: response,
                });
            }
            else {
                dispatch({
                    type: SEND_MESSAGE_MEOS,
                    payload: response,
                });

            }


        } catch (error) {
            console.error(error)
        }
    };
};
export const sendDmMediaMessageMEOS = (body: any, messageType = "public") => {
    return async (dispatch: any) => {
        try {
            const response: any = await sendDMMessageMEOS(body)
            if (messageType == 'private') {
                dispatch({
                    type: SEND_DIRECT_MESSAGE_MEOS,
                    payload: response,
                });
            }
            else {
                dispatch({
                    type: SEND_MESSAGE_MEOS,
                    payload: response,
                });

            }


        } catch (error) {
            console.error(error)
        }
    };
};



export const editChatMessage = (body: any) => {
    return async (dispatch: any) => {
        try {
            await editDMMessageMEOS(body);
        } catch (error) {
            console.error(error)
        }
    };
};

export const deleteChatMessage = (messageId: string) => {
    return async (dispatch: any) => {
        try {
            let response: any = await deleteDMMessageMEOS(messageId);
            if (response?.receiverId) {
                dispatch({
                    type: DELETE_DIRECT_MESSAGE_MEOS,
                    payload: response,
                });
            }
            else {
                dispatch({
                    type: DELETE_MESSAGE_MEOS,
                    payload: response,
                });
            }
        } catch (error) {
            console.error(error)
        }
    };
};


export const getMEOSJoinedChannelList = (body: any) => {
    return async (dispatch: any) => {
        try {
            const res = await getJoinedChannelsListMEOS({ user_id: body?.user_id });

            if (res) {
                dispatch({
                    type: GET_JOINED_CHANNELS_LIST_MEOS,
                    payload: res,
                });
            }
            else {
                dispatch({
                    type: GET_JOINED_CHANNELS_LIST_ERROR_MEOS,
                    payload: res,
                });
            }

        } catch (error) {
            console.error(error)
        }
    };
};

export const getMEOSChannelInfo = (roomId: string) => {
    return async (dispatch: any) => {
        try {

            const res = await getChannelInfoMEOS(roomId);

            if (res) {
                dispatch({
                    type: GET_CHANNEL_INFO_MEOS,
                    payload: res,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHANNEL_INFO_ERROR_MEOS,
                payload: error?.errorType,
            });
        }
    };
};

export const getMEOSPrivateMessage = (senderId: string, receiverId: string) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: CREATE_CHANNEL_MEOS_LOADING });
            const res: any = await getPrivateMessageApi(senderId, receiverId); if (res) {
                dispatch({
                    type: GET_PRIVATE_CHAT_INFO_MEOS,
                    payload: res.data.slice().reverse()
                });
            }
        } catch (error) {
            dispatch({
                type: GET_PRIVATE_CHAT_INFO_ERROR_MEOS,
                payload: error?.errorType,
            });
        }
    };
};

export const getMEOSUserInfo = (userId: string) => {
    return async (dispatch: any) => {
        try {
            const res = await getUserApi(userId);
            if (res) {
                dispatch({
                    type: GET_CHAT_USERS_INFO_MEOS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHAT_USERS_INFO_ERROR_MEOS,
                payload: error?.errorType,
            });
        }
    };
};


export const lastChatRoom = (payload: ChatRoom) => ({
    type: LAST_CHAT_ROOM,
    payload,
});



export const getMEOSChannelMembers = (roomId: string) => {
    return async (dispatch: any) => {
        try {
            dispatch({ type: CREATE_CHANNEL_MEOS_LOADING });
            const res: any = await getChannelMembers(roomId);
            const members = res.filter((data: any) => data['room_user_id']?.['name'])
            if (res) {
                dispatch({
                    type: CHANNEL_MEMBERS,
                    payload: members,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHANNEL_MEMBER_INFO_ERROR,
                payload: error?.errorType,
            });
        }
    };
}

export const getChannelMembersInfoMEO = () => {
    return async (dispatch: any) => {
        try {

            const res = await getChannelMembersInfoMEOS();

            if (res) {
                dispatch({
                    type: CHAT_MEMBERS,
                    payload: res,
                });
            }
        } catch (error) {
            dispatch({
                type: GET_CHANNEL_MEMBER_INFO_ERROR,
                payload: error?.errorType,
            });
        }
    };
};




export const setMessageEdit = () => ({
    type: SET_IS_EDIT_MESSAGE_MEOS,
    payload: true,
});

export const cancelMessageEdit = () => ({
    type: CANCEL_IS_EDIT_MESSAGE_MEOS,
    payload: false,
});

export const setMessageId = (payload: string) => ({
    type: SET_MESSAGE_ID_MEOS,
    payload
})

export const setEnteredText = (payload: string) => ({
    type: SET_ENTERED_TEXT_MEOS,
    payload,
});

export const setEditMessageId = (payload: string) => ({
    type: SET_EDIT_MESSAGE,
    payload,
});

export const updateMessageListSoRc = (payload: any) => ({
    type: UPDATE_MESSAGE_LIST_SO_MEOS,
    payload,
});

export const addMessageRealtimeMEOS = (payload: any) => {
    return async (dispatch: any) => {
        try {
            if (payload) {
                if (payload?.senderId?._id != localStorage.getItem("user")) {
                    dispatch({
                        type: SEND_MESSAGE_MEOS,
                        payload: payload,
                    });
                }
            }
        } catch (error) {
            dispatch({
                type: GET_CHANNEL_INFO_ERROR_MEOS,
                payload: error?.errorType,
            });
        }
    };

}