import { Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Input, InputAdornment, DialogActions, Button, IconButton, Select, ListItemText, Checkbox, makeStyles, Theme, createStyles, MenuItem } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React, { useEffect, useState,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createRCChannel, getRcAutoCompleteUsersList } from '../../../../../../redux/actions/chat'
import { RootState } from '../../../../../../redux/store'
import { setDayOfYear } from 'date-fns'
import { IAppChatRoom, IAppChatUser } from '../../../../../../types/app_chat'
import { createAppChannel,getMEOSJoinedChannelList } from '../../../../../../redux/actions/app_chat'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: "100%"
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "space-between",
            margin: 0,
            padding: '16px',
            "& h2.MuiTypography-root": {
                display: 'flex',
                fontWeight: 500,
                alignItems: 'center',
                justifyContent: "space-between",
                flexGrow: 1
            }
        }
    }),
);
interface selectedUsers {
    name: string;
    _id: string;
  }

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const AddChannelModal = ({ handleClose, open }) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const [channelName, setChannelName] = useState("");
    const [error, setError] = useState("");
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [personName, setPersonName] = React.useState<string[]>([]);
    const { loading, autoCompleteUsersList } = useSelector((state: RootState) => state.chatReducer);
    const members= useSelector((state: RootState) => state.appChatReducer.members);
    const currentUser=localStorage.getItem('user');
    const memberList=members?.filter((data)=>data._id !== currentUser)
    const containerRef = useRef(null);
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setPersonName(event.target.value as string[]);
    };
    const handleCheckboxChange = (user:selectedUsers) => {
        const id=user._id
        const currentScrollPosition = containerRef.current?.scrollTop;
        setSelectedUserIds((prev) =>
            prev.includes(id) ? prev.filter((userId) => userId !== id) : [...prev, id]
        );
        setPersonName([...personName,user.name])
        setTimeout(() => {
            if (containerRef.current) {
                containerRef.current.scrollTop = currentScrollPosition;
            }
        }, 0);
    };

    const handleSave = (e) => {
        e.preventDefault();
        
        if (!channelName.trim() || /\s/.test(channelName)) {
            setError("Channel name cannot contain white spaces.");
            return;
        }
        const payload: IAppChatRoom = {room_name: channelName,users:[... selectedUserIds.map((id) => ({ userId: id })), {userId:currentUser}], room_type: "group", created_by:currentUser};
        dispatch(createAppChannel(payload)).then(() => {
            handleClose();
            setChannelName('');
            setSelectedUserIds([])
            setPersonName([]);
        });

    }

    useEffect(() => {
        dispatch(getRcAutoCompleteUsersList(""));
        setChannelName('');
        setPersonName([]);
    }, [open]);


    return (
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                Create a channel
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSave}>
                <DialogContent dividers>
                    <FormControl variant="standard" style={{ width: '100%' }}>
                        <InputLabel htmlFor="input-with-icon-adornment">Name</InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            value={channelName}
                            onChange={(e) => {
                                setChannelName(e.target.value);
                                setError("");
                            }}
                            startAdornment={
                                <InputAdornment position="start">
                                    #
                                </InputAdornment>
                            }
                        />
                        {error && <p style={{ color: 'red' }}>{error}</p>}
                    </FormControl>
                    <p style={{ color: '#b0b0b0' }}>Channels are where conversations happen around a topic. Use a name that is easy to find and understand.</p>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-mutiple-checkbox-label">Members</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange}
                            placeholder='Add People'
                            input={<Input />}
                            renderValue={(selected) => (selected as string[]).join(', ')}
                            MenuProps={MenuProps}
                        ><div ref={containerRef} style={{ maxHeight: "300px", overflowY: "auto" }}>
                            {memberList?.map((user) => (
                                <MenuItem key={user?._id} value={user?.username}>
                                    <Checkbox
                                        checked={selectedUserIds.includes(user._id)}
                                        onChange={() => handleCheckboxChange(user)}
                                    />
                                    <ListItemText primary={`${user?.name} ${user?.lastName} `} />
                                </MenuItem>
                            ))}
                            </div>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        type='submit'
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save changes'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default React.memo(AddChannelModal);
