import { useHistory } from "react-router";
import { UserType } from "../constants/data";

export const isAdmin = () => {
    const authInfo = localStorage.getItem("authInfo");
    if (!authInfo) {
      return false;
    }
  
    let userDetail: any = JSON.parse(authInfo);
  
    return userDetail?.type === UserType.teamAdmin || userDetail?.type === UserType.admin;
  };
 
export const isAuthorized=()=>{
  const userData = JSON.parse(localStorage.getItem('authInfo'));
  const accessToken=localStorage.getItem('token')
  return !!(userData || accessToken);
}  