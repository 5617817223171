import {  Box,List, ListItem, makeStyles, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import HeaderWithProgressBar from '../components/HeaderProgessBar';
import ActionButtons from '../components/ActionButtons';
import { useHistory } from 'react-router';
import UserWelcomeDiv from '../components/UserWelcomeDiv';
import RenderStatusChip from '../components/RenderChipButton';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f5f7fa',
        minHeight: '100vh',
        '& .MuiButton-root': {
            cursor: 'pointer'
        },
        '& .MuiChip-root': {
            cursor: 'pointer'
        },
    },
    header: {
        justifyContent: 'center',
        '& .MuiTypography-h4': {
            fontSize: '2.75rem',
            textAlign: "center",
            fontWeight: 700,
            marginBottom: "0",
            lineHeight: "unset"
        }
    },
    progressBar: {
        margin: theme.spacing(2, 0),
        height: '10px',
        borderRadius: 0,
        backgroundColor: '#fff',
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#7CA0BB',
            borderRadius: 6
        }
    },
    chipCompleted: {
        color: '#47AF59',
        background: 'transparent'
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: `${theme.spacing(2)}px`,
        marginTop: theme.spacing(4),
        padding: `${theme.spacing(3)}px 0`,
        borderTop: '1px solid #717171',
        width: '100%',
        maxWidth: '1100px',
        margin: '0 auto'
    },
    boxWrapper: {
        width: '100%',
        maxWidth: "930px",
        margin: '0 auto',
        padding: `${theme.spacing(3)}px 0`,
        '& .MuiList-root': {
            width: '100%',
            background: 'white',
            '& .MuiListItem-root': {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                padding: 0,
                paddingBottom: theme.spacing(2),
                marginBottom: theme.spacing(2),
                borderBottom: '1px solid #F1F1F1',
            },
            '& .MuiListItem-root:last-child': {
                borderBottom: 'transparent',
                paddingBottom: theme.spacing(0),
                marginBottom: theme.spacing(0),
            }
        }
    },
    welcomeTitle: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center'
    },
    listWrapper: {
        background: 'white',
        marginBottom: theme.spacing(2),
        outline: 'none',
        borderRadius: 8,
        padding: '10px 15px',
        boxShadow: 'none',
        '&::before': {
            background: 'transparent'
        },
    },
    formLabel: {
        display: 'block',
        width: '100%',
        color: '#1E1E1E',
        fontSize: 16,
        marginBottom: theme.spacing(0.5)
    },
    formDescription: {
        display: 'block',
        width: '100%',
        color: '#686677',
        fontSize: 14
    },
    learnMoreBtn: {
        background: 'transparent',
        color: '#1F2937',
        textTransform: 'capitalize',
        textDecoration: 'underline',
        '&:hover': {
            background: "transparent"
        },
        '& .MuiButton-label': {
            fontWeight: 600,
        }
    },
    nextBtn: {
        background: '#1F2937',
        color: '#fff',
        textTransform: 'capitalize',
        borderRadius: 6,
        padding: '11px 35px',
        '&:hover': {
            background: '#2c3b50'
        },
    }
}));

function OnBoardingScreen2({ phaseNumber, markCompleted, phase, groupedContexts , handleChipClick}) {
    const classes = useStyles();
    const history = useHistory();

    const onNext = () => {
        markCompleted(2, 3, 'completed')
    }

    const onBack = () => {
        markCompleted(1, 1, null)
    }

    const currentProgress = Math.floor((phaseNumber / 3) * 100);

    return (
        <Box className={classes.root}>

            <HeaderWithProgressBar logoText="Logo" progressValue={currentProgress} />

            <Box className={classes.boxWrapper}>
                <UserWelcomeDiv />
                {Object.keys(groupedContexts).map((groupName, index) => (
                    <Box mt={3}>
                        {
                            groupName && groupName !== "Ungrouped" &&
                            <Typography variant='h6' style={{ fontWeight: 700, display: 'flex', color: '#000000DE', alignItems: 'center', gap: 6, marginBottom: '10px' }}>
                                {groupName} <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="9.5" stroke="#FF9C00" />
                                    <path d="M9.61554 3.79417C9.41271 3.87768 9.12971 4.11255 8.99764 4.31088C8.78066 4.63448 8.72878 4.94242 8.75708 5.63137C8.8467 7.5312 9.07782 11.7067 9.10141 11.7954C9.20989 12.2025 9.59195 12.5 10.007 12.5C10.2995 12.5 10.4693 12.4321 10.6438 12.2495C10.9268 11.9572 10.9174 12.0407 11.106 8.48112C11.2947 5.05725 11.2947 4.82238 11.1249 4.46224C11.0211 4.24303 10.7712 3.97685 10.5542 3.86202C10.3372 3.75242 9.81364 3.71066 9.61554 3.79417Z" fill="#FF9C00" />
                                    <path d="M9.75278 13.7837C9.086 13.9056 8.63354 14.5808 8.77642 15.2465C8.99074 16.2592 10.2386 16.5968 10.9387 15.8326C11.0578 15.7013 11.1768 15.5184 11.2054 15.4247C11.2721 15.1856 11.2626 14.773 11.1864 14.5573C11.0482 14.1775 10.6815 13.8587 10.3195 13.7978C10.2148 13.779 10.1005 13.7603 10.0624 13.7509C10.0243 13.7462 9.88613 13.7603 9.75278 13.7837Z" fill="#FF9C00" />
                                </svg>
                            </Typography>
                        }
                        <div className={classes.listWrapper}>
                            <List>
                                {groupedContexts[groupName].map((context, subIndex) => (
                                    <ListItem key={subIndex}>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography className={classes.formLabel} variant='h6'>{context.label}</Typography>
                                            <Typography className={classes.formDescription} variant='caption'>{context.description}</Typography>
                                        </Box>
                                        <RenderStatusChip context={context} onChipClick={handleChipClick}  /> 
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                    </Box>
                ))}
            </Box>

            <ActionButtons
                            phaseNumber={2}
                learnMoreText='Learn More'
                nextText='Next: Establish Balance & Focus'
                onLearnMore={() => console.log('Learn More clicked')}
                onNext={() => onNext()}
                backText='Back: Course Phase'
                onBack={() => onBack()}
                isDisabledNext={false}
                //isDisabledNext={(phaseNumber === 2 || phaseNumber === 1) ? true: false}
            />

        </Box>
    );
}

export default OnBoardingScreen2;
