import { Dialog, DialogTitle, DialogContent, IconButton, makeStyles, Theme, createStyles, Tabs, Tab, Box, Typography, Avatar } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { useHistory } from 'react-router'
import { getAvatarBackgroundColor, useChatStyle } from '../../style'

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            width: "100%"
        },
        dialogTitle: {
            display: 'flex',
            justifyContent: "space-between",
            margin: 0,
            padding: '16px',
            textTransform: "capitalize",
            "& h2.MuiTypography-root": {
                display: 'flex',
                fontWeight: 500,
                alignItems: 'center',
                justifyContent: "space-between",
                flexGrow: 1
            }
        },
        tabsDiv: {
            '& .MuiTabs-fixed': {
                marginBottom: 20,
                ' & .MuiButtonBase-root': {
                    textTransform: 'capitalize',
                    color: '#1E1E1E',
                    fontSize: 16
                },
                '& .MuiTab-textColorPrimary.Mui-selected': {
                    color: '#202A44',
                    fontWeight: 700
                },
                '& .PrivateTabIndicator-colorPrimary-35': {
                    backgroundColor: '#7CA0BB !important'
                }

            }
        },
        dialogChannelMember: {
            maxHeight: 'calc(100vh - 400px)', // Add your desired rule here
            overflowY: 'auto', // Optional to enable scrolling if content overflows
        },


    }),
);

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const AddChannelModal = ({ handleClose, open }) => {
    const classes = useStyles();
    const chatClasses = useChatStyle();
    const channelMembers = useSelector((state: RootState) => state.appChatReducer.channelMembers);
    const chatChannelInfo = useSelector((state: RootState) => state.appChatReducer.chatChannelInfo);
    const channelClientRecords = channelMembers?.filter((data) => data['room_user_id']?.['type'] === 'client')
    const channelCoachRecords = channelMembers?.filter((data) => data['room_user_id']?.['type'] === 'coach')
    const [value, setValue] = React.useState(0);
    const history: any = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const clickToChat = (userId: string) => {
        history.push(`/user/chat?roomId=${userId}&type=direct`)
        handleClose()
    }

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth="md"
        >
            <DialogTitle className={classes.dialogTitle} id="customized-dialog-title">
                {chatChannelInfo?.['room_name']}
                <IconButton aria-label="close" onClick={handleClose}>
                    <Close />
                </IconButton>
            </DialogTitle>

            <Tabs
                className={classes.tabsDiv}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Team Members" {...a11yProps('1')} />
                <Tab label="Team Coach" {...a11yProps('2')} />
                <Tab label="Team Client" {...a11yProps('2')} />

            </Tabs>
            <TabPanel value={value} index={0} >
                <DialogContent className={classes.dialogChannelMember}>
                    {channelMembers?.length > 0 ? (
                        <Box display="flex" flexDirection="column" style={{ cursor: "pointer" }}>
                            {channelMembers?.map((member) => {
                                const user = member['room_user_id'];
                                if (!user) return null;
                                const name = user['name'] || 'Unknown User';
                                const bgColorClass = getAvatarBackgroundColor(name);
                                return (
                                    <Box
                                        key={user['_id']} // Use user ID as key for better stability
                                        display="flex"
                                        alignItems="center"
                                        padding={1}
                                        border="1px solid #ddd"
                                        borderRadius="8px"
                                        onClick={() => clickToChat(user['_id'])}
                                    >
                                        {member['room_user_id']?.['avatarUrl']
                                            ?
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                                src={`${process.env.REACT_APP_API_URL}/${member['room_user_id']?.['avatarUrl']}`}
                                            >

                                            </Avatar>
                                            :
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                            >
                                                <span style={{ color: 'white', fontSize: '20px' }}>
                                                    {name.charAt(0).toUpperCase()}
                                                </span>
                                            </Avatar>
                                        }

                                        <Typography variant="body1" style={{ marginLeft: 10, textTransform: "capitalize" }}>
                                            {name}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No members available.
                        </Typography>
                    )}
                </DialogContent>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DialogContent className={classes.dialogChannelMember}>
                    {channelCoachRecords?.length > 0 ? (
                        <Box display="flex" flexDirection="column" style={{ cursor: "pointer" }}>
                            {channelCoachRecords?.map((coach, index) => {
                                const user = coach['room_user_id'];
                                if (!user) return null;
                                const name = user['name'] || 'Unknown User';
                                const bgColorClass = getAvatarBackgroundColor(name);
                                return (
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        padding={1}
                                        border="1px solid #ddd"
                                        borderRadius="8px"
                                    >
                                        {coach['room_user_id']?.['avatarUrl']
                                            ?
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                                src={`${process.env.REACT_APP_API_URL}/${coach['room_user_id']?.['avatarUrl']}`}
                                            >

                                            </Avatar>
                                            :
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                            >
                                                <span style={{ color: 'white', fontSize: '20px' }}>
                                                    {name.charAt(0).toUpperCase()}
                                                </span>
                                            </Avatar>
                                        }

                                        <Typography variant="body1" style={{ marginLeft: 10, textTransform: "capitalize" }} >
                                            {coach['room_user_id']?.['name']}
                                        </Typography>
                                    </Box>

                                )
                            }

                            )}
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No Coach available.
                        </Typography>
                    )}
                </DialogContent>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DialogContent className={classes.dialogChannelMember}>
                    {channelClientRecords?.length > 0 ? (
                        <Box display="flex" flexDirection="column" style={{ cursor: "pointer" }}>
                            {channelClientRecords?.map((client, index) => {
                                const user = client['room_user_id'];
                                if (!user) return null;
                                const name = user['name'] || 'Unknown User';
                                const bgColorClass = getAvatarBackgroundColor(name);
                                return (
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        padding={1}
                                        border="1px solid #ddd"
                                        borderRadius="8px"
                                    >
                                        {client['room_user_id']?.['avatarUrl']
                                            ?
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                                src={`${process.env.REACT_APP_API_URL}/${client['room_user_id']?.['avatarUrl']}`}
                                            >

                                            </Avatar>
                                            :
                                            <Avatar
                                                alt={name}
                                                style={{ width: '45px', height: '45px', cursor: 'pointer' }}
                                                className={chatClasses[bgColorClass]}
                                            >
                                                <span style={{ color: 'white', fontSize: '20px' }}>
                                                    {name.charAt(0).toUpperCase()}
                                                </span>
                                            </Avatar>
                                        }
                                        <Typography variant="body1" style={{ marginLeft: 10, textTransform: "capitalize" }} >
                                            {client['room_user_id']?.['name']}
                                        </Typography>
                                    </Box>

                                )
                            }
                            )}
                        </Box>
                    ) : (
                        <Typography variant="body2" color="textSecondary">
                            No Client available.
                        </Typography>
                    )}
                </DialogContent>
            </TabPanel>
        </Dialog>

    )
}

export default React.memo(AddChannelModal);
