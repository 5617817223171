import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { getProgramFormContextData, getProgramFormStructure, getProgramListContextData, submitProgramForm } from '../../../redux/actions/programFormAction';
import { RootState } from '../../../redux/store';
import ButtonAppBar from '../../../components/AppBar';
import { useStyles } from './style';
import FormControlComponent from './components/FormControl';
import HeaderNotificationIcon from '../../../components/HeaderNotification';
import { IProgramEnrollment } from '../../../types/programEnrollment';
import "./style.scss";
import { MoonLoader } from 'react-spinners';

type FormRenderProps = {
    formDetail: any,
    formResult: any,
    formId?: string,
    sourceFormId?: string,
    userId?: string,
    contextId?: string;
    programId?: string;
    isOnboarding?: boolean;
    year?: number;
    onCloseModal?: () => void;
    openModal?: boolean;
    requiredQuestions?: boolean
}

export const FormRenderer = forwardRef(({
    formDetail,
    formResult,
    formId,
    sourceFormId,
    userId,
    contextId,
    programId,
    isOnboarding = false,
    year,
    openModal,
    onCloseModal,
    requiredQuestions
}: FormRenderProps, ref) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState<any>({});
    const [formErrors, setFormErrors] = useState({});
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [formQuestions, setFormQuestions] = useState<any>([])
    const classes = useStyles();
    const { programFormContextInfo, programFormContextUserInfo } = useSelector((state: RootState) => state.programReducer)
    const { loading } = useSelector((state: RootState) => state.programEnrollmentReducer)
    const location = useLocation();
    const calculateScalePercentage = useCallback((selectedValue, scaleFrom, scaleAmount) => {
        // return ((selectedValue - scaleFrom) / (scaleAmount - 1)) * 100;
        return (selectedValue / scaleAmount) * 100;
    }, []);
    const calculateTotalScalePercentage = useCallback(() => {
        let totalPercentage = 0;
        let scaleCount = 0;

        Object.entries(formDetail.questions).forEach(([key, question]: any) => {
            if (question.type === 'control_scale') {
                const selectedValue = formData[question.name];
                const scaleFrom = parseInt(question.scaleFrom, 10);
                const scaleAmount = parseInt(question.scaleAmount, 10);

                if (selectedValue) {
                    totalPercentage += calculateScalePercentage(parseInt(selectedValue, 10), scaleFrom, scaleAmount);
                    scaleCount += 1;
                }
            }
        });

        return scaleCount > 0 ? (totalPercentage / scaleCount).toFixed(2) : '0';
    }, [formData, formDetail.questions, calculateScalePercentage]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [name]: '',
        }));
    };


    const onFormDataChange = useCallback((data) => {
        setFormData((prevData) => {
            const isEqual = JSON.stringify(prevData) === JSON.stringify(data);
            if (!isEqual) {
                return data;
            }
            return prevData;
        });
    }, []);



    const validateForm = () => {
        let errors = {};
        let isValid = true;
        Object.entries(formDetail.questions).forEach(([key, question]: any) => {
            const value = formData[question.name];
            if (question.required === 'Yes') {
                if (question.hidden == 'Yes') {
                    isValid = true;
                }
                else if (value === undefined || value === null || value === '') {
                    errors[question.name] = `${question.text} is required.`;
                    isValid = false;
                }
            }
        });

        setFormErrors(errors);
        return isValid;
    };


    const handleSubmit = (e: React.FormEvent, result = null) => {
        e.preventDefault();
        if (result === 'leaveAndDiscard') {
            setFormData({});
            if (isOnboarding) {
                onCloseModal();
            }
        }
        else {
            if (validateForm()) {
                const payload = { data: formData };
                if(result)
                {
                    const parsedResult = JSON.parse(result);
                    if(parsedResult.skipped)
                    {
                        payload.data.skipped=parsedResult.skipped
                    }
                }
                else{
                    delete  payload.data.skipped
                }
             
                dispatch(submitProgramForm({
                    programId,
                    sourceFormId,
                    userId,
                    contextId,
                    year: year ? year.toString() : '',
                    payload
                }));
                setFormData({});
                // if (isOnboarding) {
                //     onCloseModal();
                // }
            }
        }
    };

    useImperativeHandle(ref, () => ({
        handleSubmit,
    }));

    useEffect(() => {
        setFormData({});
    }, [location, openModal]);

    useEffect(() => {

        const calculationResult = calculateTotalScalePercentage();

        if (formData.calculationshown !== calculationResult) {
            setFormData((prevData) => ({
                ...prevData,
                calculationshown: calculationResult,
            }));
        }
        

    }, [calculateTotalScalePercentage, formData.calculationshown]);

    const isFormDataInitialized = useRef(false);

    useEffect(() => {
        if (formDetail && formDetail.questions && !isFormDataInitialized.current) {
            const initialFormData = {};

            Object.entries(formDetail.questions).forEach(([key, question]: any) => {
                initialFormData[question.name] = question.defaultValue || '';
            });

            if (Object.keys(initialFormData).length > 0) {
                setFormData(initialFormData);
                isFormDataInitialized.current = true;
            }
        }
        const sortedQuestions = Object.entries(formDetail.questions)
            .sort(([, a]: any, [, b]: any) => {
                const orderA = parseInt(a.order, 10) || 0;
                const orderB = parseInt(b.order, 10) || 0;
                return orderA - orderB;
            })
            .map(([_, question]) => question);
        const pages = splitFormByPageBreak(sortedQuestions);
         setFormQuestions(pages)
    }, [formDetail]);

    useEffect(() => {
        if (isFormDataInitialized.current && formData && programFormContextInfo && programFormContextUserInfo) {
            const updatedFormData = { ...formData };
            Object.entries(formData).forEach(([key, value]: any) => {
                switch (key.toLowerCase()) {
                    case 'duedate':
                        if (!value) {
                            updatedFormData[key] = programFormContextInfo.dueDate;
                        }
                        break;

                    case 'name':
                        if (!value) {
                            updatedFormData[key] = programFormContextUserInfo.name;
                        }
                        break;

                    case 'lastname':
                        if (!value) {
                            updatedFormData[key] = programFormContextUserInfo.lastName;
                        }
                        break;

                    case 'currentdate':
                        if (!value) {
                            updatedFormData[key] = new Date();
                        }
                        break;

                    default:
                        updatedFormData[key] = value || '';
                        break;
                }
            });

            setFormData(updatedFormData);
        }
    }, [programFormContextInfo, programFormContextUserInfo]);

    useEffect(() => {
        if (formResult && formResult !== null && isFormDataInitialized.current) {
            const updatedFormData = { ...formData };
            Object.entries(formResult).forEach(([key, value]: any) => {
                updatedFormData[key] = value?.hasOwnProperty('value') ? value?.value : (value || '');
            });
            setFormData(updatedFormData);
        }
    }, [formResult, location, openModal]);
    const handleNextPage = () => {
        let index = currentPageIndex + 1
        setCurrentPageIndex(index)
    }
    const handleBackPage = () => {
        let index = currentPageIndex - 1;
        setCurrentPageIndex(index);
    }


    return (
        <>
            <div className={classes.container}>
                {loading && <div className={classes.loader} ><MoonLoader size={40} loading /></div>}
                <form onSubmit={handleSubmit}>
                    {formQuestions[currentPageIndex] && Array.isArray(formQuestions[currentPageIndex]) && formQuestions[currentPageIndex].length > 0 &&
                        formQuestions[currentPageIndex].map((question, key) => {
                            if (question.hidden === "Yes") {
                                return null;
                            }
                            return (
                                <FormControlComponent
                                    key={key}
                                    question={question}
                                    formData={formData}
                                    handleChange={handleChange}
                                    setFormData={setFormData}
                                    error={formErrors[question.name]}
                                    backButton={location.pathname === '/user/form/show'}
                                    onNext={handleNextPage}
                                    onBack={handleBackPage}
                                    currentPageIndex={currentPageIndex}
                                    onFormDataChange={onFormDataChange}
                                />
                            );
                        })
                    }
                    <div className="form-group">
                    </div>

                </form>
            </div>
        </>
    );
});

const BackPack = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const searchParams = new URLSearchParams(location.search);
    const sourceFormId: string = searchParams.get('sourceFormId');
    const formId: string = searchParams.get('formId');
    const userId: string = searchParams.get('userId');
    const contextId: string = searchParams.get('contextId');
    const programEnrollment: IProgramEnrollment = JSON.parse(localStorage.getItem('activeProgramEnrollment')) || {};
    const programId = programEnrollment?.programId;

    const { formStructure, error, userformData, loading } = useSelector((state: RootState) => state.programFormReducer)

    useEffect(() => {
        if (userId && sourceFormId) {
            dispatch(getProgramFormStructure(programId, sourceFormId, userId));
            dispatch(getProgramFormContextData(programId, sourceFormId, userId, contextId));
            return;
        }
    }, [location]);
    if (error && error !== null) {
        return <div className={classes.noDataFound}>No Form Found</div>
    }

    if (loading) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}><MoonLoader size={40} loading /></div>
    }

    return (
        <>
            {userformData && <ButtonAppBar title={userformData?.data?.title} back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />}
            {formStructure && <FormRenderer
                formResult={userformData}
                formDetail={formStructure}
                sourceFormId={sourceFormId}
                userId={userId}
                formId={formId}
                contextId={contextId}
                programId={programId}
            />}
        </>
    )
}

const splitFormByPageBreak = (questions: any) => {
    const pages: any = [];
    let currentPage: any = [];

    questions.forEach((question) => {
        if (question.type === 'control_pagebreak' && currentPage.length > 0) {
            currentPage.push(question);
            pages.push(currentPage);
            currentPage = [];
        } else {
            currentPage.push(question);
        }
    });

    if (currentPage.length > 0) {
        pages.push(currentPage);
    }

    return pages;
};

export default BackPack;
