import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelMessageEdit,
  deleteChatMessage,
  editChatMessage,
  lastChatRoom,
  setEditMessageId,
  setMessageEdit,
} from "../../../../redux/actions/app_chat";
import { RootState } from "../../../../redux/store";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import MoreVert from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import { useHistory, useLocation } from "react-router-dom";
import {
  AttachFile,
  Create,
  FiberManualRecord,
  InsertDriveFile,
  Search,
} from "@material-ui/icons";
import moment from "moment";
import UploadFile from "./components/modal/uploadFile";
import { useChatStyle, getAvatarBackgroundColor } from "./style";
import {
  getChatMessagesMEOS,
  getMEOSChannelInfo,
  getMEOSJoinedChannelList,
  sendDmMessageMEOS,
  setEnteredText,
  setMessageId,
  getMEOSUserInfo,
  getMEOSPrivateMessage,
  getActiveMessage,
  getMEOSChannelMembers,
  getLastChatUsers,
} from "../../../../redux/actions/app_chat";
import { IMessageMEOS } from "../../../../types/app_chat";
import { useSocket } from "../../../../provider/socketProvider";
import ChannelMemberModal from "./components/modal/ChannelMemberModal";
import { ChatRoom } from "../../../../types/chat";
import CircularProgress from '@material-ui/core/CircularProgress';

const Chat = () => {
  const ITEM_HEIGHT = 48;
  const classes = useChatStyle();
  const dispatch = useDispatch();
  const history: any = useHistory();
  const location = useLocation<any>();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("roomId");
  const roomType = searchParams.get("type");
  const [selectedFile, setSelectedFile] = useState(null);
  const [file, setFile] = useState("");
  const [messageAttachments, setMessageAttachments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    messages,
    chatUserInfo,
    chatChannelInfo,
    isEditMessage,
    enteredText,
    messageId,
    error,
    directMessagesList,
    channelMembers,
    activeMessages,
    editMessageId,
    latestChatUser,
    loading,
  } = useSelector((state: RootState) => state.appChatReducer);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const userDetailId = localStorage.getItem("user");
  const { onMessage, offMessage } = useSocket();
  const [renderMessages, setRenderMessages] = useState([]);
  const [memberModal, setMemberModal] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    messageId: string
  ) => {
    setAnchorEl(event.currentTarget);
    dispatch(setMessageId(messageId));
  };
  useEffect(() => {
    dispatch(getLastChatUsers(userDetailId));
    dispatch(cancelMessageEdit());
    if (id && roomType === "direct") {
      let roomInfo: ChatRoom = { room: id, roomType: "direct" };
      dispatch(getMEOSUserInfo(id));
      dispatch(getMEOSPrivateMessage(userDetailId, id));
      setRenderMessages(directMessagesList);
      dispatch(lastChatRoom(roomInfo));
    }
    if (id && roomType === "channel") {
      let roomInfo: ChatRoom = { room: id, roomType: "channel" };
      dispatch(getMEOSChannelInfo(id));
      dispatch(getMEOSChannelMembers(id));
      dispatch(getChatMessagesMEOS(id));
      dispatch(lastChatRoom(roomInfo));
      setRenderMessages(messages);
    }
  }, [id, roomType, dispatch]);
  useEffect(() => {
    if (id && roomType === "direct") {
      setRenderMessages(directMessagesList);
    }
    if (id && roomType === "channel") {
      setRenderMessages(messages);
    }
  }, [directMessagesList, messages]);

  useEffect(() => {
    const messageListener = async (message: any) => {
      if (id) {
        dispatch(getChatMessagesMEOS(id));
      }
    };
    const recievePrivateMessageListener = async (message: any) => {
      let userList = latestChatUser?.filter((data: any) => data?.userId === message["senderId"]["_id"])
      if (userList?.length == 0) {
        dispatch(getLastChatUsers(userDetailId));
      }
      dispatch(getMEOSPrivateMessage(userDetailId, id));
      const unseenMessage = [...activeMessages, message["senderId"]["_id"]];
      dispatch(getActiveMessage(unseenMessage));
    };

    const notificationListener = async (message: any) => {
      dispatch(getMEOSJoinedChannelList({ user_id: userDetailId }));
    };

    // Attach listeners
    onMessage("receiveMessage", messageListener);
    onMessage("receivePrivateMessage", recievePrivateMessageListener);
    onMessage("createChannel", notificationListener);

    // Cleanup listeners on unmount
    return () => {
      // Assuming `offMessage` is the correct way to remove listeners
      offMessage("receiveMessage", messageListener);
      offMessage("createChannel", notificationListener);
      offMessage("receivePrivateMessage", recievePrivateMessageListener);
    };
  }, [id, userDetailId, dispatch, onMessage, activeMessages]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (message: IMessageMEOS) => {
    dispatch(setEditMessageId(message._id));
    let messageText = message.message_text || "";
    if (message.images && message.images.length > 0) {
      const attachmentsDescription = message.images
        .map((att) => att || "")
        .join("\n");
      messageText += `\n${attachmentsDescription}`;
    }
    dispatch(setMessageEdit());
    dispatch(setEnteredText(messageText));
    handleClose();
  };

  const handleSendMessage = () => {
    let isReceiverInChat = true;
    let payload = {
      room_id: id,
      message_text: enteredText.trim(),
      senderId: localStorage.getItem("user"),
    };
    if (roomType == "direct") {
      payload["receiverId"] = id;
      isReceiverInChat = latestChatUser.some(
        (user: any) => user.userId === payload["receiverId"]
      );
    }
    dispatch(sendDmMessageMEOS(payload));
    dispatch(setEnteredText(""));
    if (!isReceiverInChat) {
      dispatch(getLastChatUsers(userDetailId));
    }
  };

  const handleEditMessage = () => {
    let payload = {
      room_id: id,
      message_text: enteredText.trim(),
      senderId: localStorage.getItem("user"),
      messageId: editMessageId,
    };

    if (roomType == "direct") {
      payload["receiverId"] = id;
    }
    dispatch(editChatMessage(payload));
    dispatch(cancelMessageEdit());
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (isEditMessage) {
        handleEditMessage();
      } else {
        handleSendMessage();
      }
    }
  };

  const deleteMessage = (msgId: string) => {
    dispatch(deleteChatMessage(msgId));
    handleClose();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessageAttachments(e.target.files);
      setSelectedFile(URL.createObjectURL(file));
      setFile(file);
      setIsModalOpen(true);
    }
  };

  const handleChangeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behaviour: "smooth" });
    }
  }, [renderMessages]);

  const selectMember = () => {
    dispatch(getMEOSChannelMembers(id));
    setMemberModal(true);
  };

  if (
    error &&
    (error === "error-room-not-found" || error === "invalid-channel")
  ) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
      >
        <Search />
        <Typography variant="h6">Room not found</Typography>
        <Typography variant="body2">
          The room does not exist or you may not have access permission.
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Paper elevation={3} className={classes.chatWrapper}>
        {(chatUserInfo || chatChannelInfo) && !loading && (
          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px solid lightgray"
            paddingBottom="10px"
          >
            <Avatar
              style={{ width: "45px", height: "45px", cursor: "pointer" }}
              alt={
                roomType === "direct"
                  ? chatUserInfo?.name
                  : chatChannelInfo?.room_name
              }
              className={
                classes[
                getAvatarBackgroundColor(
                  roomType === "direct"
                    ? chatUserInfo?.name
                    : chatChannelInfo?.room_name
                )
                ]
              }
            >
              {roomType === "direct" ? (
                chatUserInfo?.avatarUrl ? (
                  <Avatar
                    style={{ width: "45px", height: "45px", cursor: "pointer" }}
                    alt={chatUserInfo?.name}
                    className={
                      classes[getAvatarBackgroundColor(chatUserInfo?.name)]
                    }
                    src={`${process.env.REACT_APP_API_URL}/${chatUserInfo?.avatarUrl}`}
                  />
                ) : (
                  <span style={{ color: "white", fontSize: "20px" }}>
                    {chatUserInfo?.name.charAt(0).toUpperCase()}
                  </span>
                )
              ) : (
                <span style={{ color: "white", fontSize: "20px" }}>
                  {chatChannelInfo?.room_name.charAt(0).toUpperCase()}
                </span>
              )}
            </Avatar>
            <Box marginLeft="1rem" display="flex" alignItems="center">
              {roomType === "direct" && (
                <FiberManualRecord
                  style={{
                    // color: statusColors[chatUserInfo?.status || 'offline'],
                    color: "green",
                    fontSize: "16px",
                    marginRight: "5px",
                  }}
                />
              )}
              <Typography variant="h6">
                <span style={{ textTransform: "capitalize" }}>
                  {roomType === "direct"
                    ? chatUserInfo?.name
                    : `# ${chatChannelInfo?.room_name}`}
                </span>
              </Typography>
            </Box>
            {roomType === "channel" && channelMembers?.length > 0 && (
              <Box
                marginLeft="auto"
                display="flex"
                alignItems="center"
                onClick={selectMember}
              >
                <AvatarGroup
                  max={4}
                  style={{ display: "flex", gap: "5px" }}
                  className="AvatarGroup"
                >
                  {channelMembers?.length > 0 &&
                    channelMembers?.map((member, index) => {
                      const name =
                        member["room_user_id"]?.["name"] || "Unknown User";
                      const bgColorClass = getAvatarBackgroundColor(name);
                      return (
                        <div className="groupmembers" key={index}>
                          {member["room_user_id"]?.avatarUrl ? (
                            <Avatar
                              alt={name}
                              style={{ cursor: "pointer" }}
                              src={`${process.env.REACT_APP_API_URL}/${member["room_user_id"]?.avatarUrl}`}
                            >
                              <span
                                style={{ color: "white", fontSize: "20px" }}
                              >
                                {name.charAt(0).toUpperCase()}
                              </span>
                            </Avatar>
                          ) : (
                            <Avatar
                              alt={name}
                              style={{ cursor: "pointer" }}
                              className={classes[bgColorClass]}
                            >
                              <span
                                style={{ color: "white", fontSize: "20px" }}
                              >
                                {name.charAt(0).toUpperCase()}
                              </span>
                            </Avatar>
                          )}
                        </div>
                      );
                    })}
                </AvatarGroup>
              </Box>
            )}
          </Box>
        )}
        {!loading ? (
          <>
            <Box overflow="auto" flexGrow={1} marginY="1rem">
              {renderMessages?.length > 0 ? (
                renderMessages.map((message: IMessageMEOS, index: number) => {
                  const name = message?.senderId?.name || "Unknown User";
                  const bgColorClass = getAvatarBackgroundColor(name);
                  return (
                    <Box
                      key={index}
                      display="flex"
                      alignItems="center"
                      marginBottom="1rem"
                    >
                      {message?.senderId?.avatarUrl ? (
                        <Avatar
                          alt={name}
                          style={{ cursor: "pointer" }}
                          src={`${process.env.REACT_APP_API_URL}/${message?.senderId?.avatarUrl}`}
                          className={classes[bgColorClass]}
                        ></Avatar>
                      ) : (
                        <Avatar
                          alt={name}
                          style={{ cursor: "pointer" }}
                          className={classes[bgColorClass]}
                        >
                          <span style={{ color: "white", fontSize: "20px" }}>
                            {name.charAt(0).toUpperCase()}
                          </span>
                        </Avatar>
                      )}
                      <Box marginLeft="1rem" flexGrow={1}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="subtitle1"
                            onClick={() =>
                              history.push(
                                `/user/chat?roomId=${message.senderId._id}&type=direct`
                              )
                            }
                            style={{
                              fontWeight: "bold",
                              fontSize: "16px",
                              cursor: "pointer",
                              textTransform: "capitalize",
                            }}
                          >
                            {message.senderId.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            color="textSecondary"
                            style={{ marginLeft: "10px" }}
                          >
                            {moment(message.createdAt).format("LT")}
                          </Typography>
                        </Box>
                        {message?.message_text && (
                          <Typography
                            variant="body1"
                            style={{ wordBreak: "break-all", fontSize: "14px" }}
                          >
                            {message?.message_text}
                          </Typography>
                        )}

                        {message.images &&
                          renderAttachments(message.images, message)}
                      </Box>
                      {userDetailId == message.senderId._id && (
                        <>
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleClick(event, message._id)}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={open && messageId == message._id}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: "15ch",
                              },
                            }}
                          >
                            <MenuList>
                              <MenuItem
                                button
                                onClick={() => handleEdit(message)}
                              >
                                <ListItemIcon>
                                  <CreateIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Edit</Typography>
                              </MenuItem>
                              <MenuItem
                                button
                                onClick={() => {
                                  deleteMessage(message._id);
                                }}
                              >
                                <ListItemIcon>
                                  <DeleteIcon color="error" fontSize="small" />
                                </ListItemIcon>
                                <Typography color="error" variant="inherit">
                                  Delete
                                </Typography>
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </>
                      )}
                    </Box>
                  );
                })
              ) : (<></>
                // <div>No Messages</div>
              )}

              {/* </InfiniteScroll> */}
              <div ref={ref} />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              padding="10px 10px 0"
              borderTop="1px solid lightgray"
            >
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Enter Message"
                value={enteredText}
                onChange={(e) => dispatch(setEnteredText(e.target.value))}
                onKeyDown={handleKeyDown}
                multiline
                rowsMax={4}
              />
              <Tooltip title="Upload file">
                <IconButton component="label">
                  <AttachFile />
                  <input
                    type="file"
                    accept="image/*,video/*"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </IconButton>
              </Tooltip>
              {isEditMessage ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => dispatch(cancelMessageEdit())}
                    style={{ marginLeft: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEditMessage}
                    style={{ marginLeft: "10px" }}
                    disabled={!enteredText}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                  style={{ marginLeft: "10px" }}
                  disabled={!enteredText}
                >
                  Send
                </Button>
              )}
            </Box>
          </>
        ) : (<>
          <CircularProgress size={50} className={classes.loader} />

        </>)
        }
      </Paper>

      <UploadFile
        roomId={id}
        roomType={roomType}
        isModalOpen={isModalOpen}
        handleChangeModal={handleChangeModal}
        selectedImg={selectedFile}
        file={file}
        setFile={setFile}
        setSelectedImg={setSelectedFile}
        attachments={messageAttachments}
      />
      <ChannelMemberModal
        open={memberModal}
        handleClose={() => setMemberModal(false)}
      />
    </>
  );
};

export default Chat;

const getFileUrl = (fileId) => {
  return `${process.env.REACT_APP_API_URL}/uploads/messages/${fileId}`;
};

const renderAttachments = (attachments, message) => {
  return attachments.map((attachment, index) => {
    // const { title, image_url, image_dimensions, video_url, audio_url, type, description } = attachment;
    if (attachment) {
      return (
        <>
          <Typography
            variant="body1"
            style={{ marginTop: "5px", wordBreak: "break-all" }}
          >
            {attachment}
          </Typography>
          {message?.message_type == "video" ? (
            <video controls width="500">
              <source src={getFileUrl(attachment)} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img
              key={index}
              src={getFileUrl(attachment)}
              alt={attachment}
              style={{
                maxWidth: "100%",
                borderRadius: "8px",
                marginTop: "10px",
              }}
              width={150}
              height={150}
            />
          )}
        </>
      );
    }
    return null;
  });
};
