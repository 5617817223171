import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CustomDialogTitle from './DialogTitle';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useStyles } from '../../style';
import { ITeam } from '../../../../../types/team';
type UpdateTeamDialogProps = {
    open: boolean;
    handleClose: () => void;
    data: ITeam | null
    onSave: (updatedData) => void;
};

const AnnounceMentActionDialog = (props: UpdateTeamDialogProps) => {
    const theme = useTheme();
    const { open, handleClose, data, } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const { control, handleSubmit, errors, reset, setValue, setError } = useForm({
        defaultValues: {}
    });

    useEffect(() => {
        reset(data);
    }, [data, reset]);

    const onSubmit = (formData) => {
        let records = formData
        records.userId = localStorage.getItem('user')
        if (data && data?._id) {
            records._id = data?._id
        }
        props.onSave({
            ...records
        });
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomDialogTitle id="form-dialog-title">{`${!data ? 'Add' : 'Update'}`} Announcement</CustomDialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" style={{ gap: 20 }}>
                        <DialogContentText>
                            {`${!data ? 'Add' : 'Update'}`} the details of the form below. Make necessary changes and click "Save" to update the form.
                        </DialogContentText>
                        <Grid item xs={12}>
                            <Controller
                                name="label"
                                control={control}
                                rules={{ required: 'Announcement Label required.' }}
                                defaultValue=""
                                as={
                                    <TextField
                                        id="outlined-label"
                                        aria-describedby="outlined-label-helper-text"
                                        inputProps={{
                                            'aria-label': 'label',
                                        }}
                                        placeholder="Label"
                                        label="Label"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.label}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors} x
                                name="name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                name="body"
                                control={control}
                                rules={{ required: 'Announcement body required' }}
                                defaultValue=""
                                as={
                                    <TextField
                                        id="outlined-description"
                                        aria-describedby="outlined-label-helper-text"
                                        inputProps={{
                                            'aria-label': 'label',
                                        }}
                                        placeholder="Body"
                                        label="Body"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                        rows={4}
                                        error={!!errors.name}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="name"
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                        {`${!data ? 'Save' : 'Update'}`}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(AnnounceMentActionDialog);
