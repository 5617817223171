import { Chip } from '@material-ui/core';
import { BorderColor, CheckCircleOutline, Lock } from '@material-ui/icons';
import React from 'react'
import { useStyles } from '../../style';
import Show from '../../../../../components/Show';

const RenderStatusChip = ({ context, onChipClick,groupedContexts=null }) => {
    const classes = useStyles();
    const { status } = context;

    return (
        <>
            <Show when={status === 'Completed'}>
                <Chip
                    label="Completed"
                    icon={<CheckCircleOutline className={classes.chipCompleted} />}
                    className={classes.chipCompleted}
                    onClick={() => onChipClick(context)}
                />
            </Show>
            <Show when={status === 'Incomplete'}>
                <Chip
                    label="InCompleted"
                    icon={<BorderColor style={{ color: 'white', background: '#FF9C00', borderRadius: '50%', padding: '4px', fontSize: '14px' }} />}
                    className={classes.chipPending}
                    onClick={() => onChipClick(context)}
                />
            </Show>
            <Show when={status === 'Not Ready'}>
                <Chip
                    label="InCompleted"
                    icon={<BorderColor style={{ color: 'white', background: '#FF9C00', borderRadius: '50%', padding: '4px', fontSize: '14px' }} />}
                    className={classes.chipPending}
                    onClick={() => onChipClick(context)}
                />
            </Show>
            <Show when={status === 'Pending'}>
                <Chip
                    label="Pending"
                    icon={<BorderColor style={{ color: 'white', background: '#FF9C00', borderRadius: '50%', padding: '4px', fontSize: '14px' }} />}
                    className={classes.chipPending}
                    onClick={() => onChipClick(context)}
                />
            </Show>
            <Show when={status === 'Overdue'}>
                <Chip
                    label="Overdue"
                    icon={<BorderColor style={{ color: 'white', background: '#FF9C00', borderRadius: '50%', padding: '4px', fontSize: '14px' }} />}
                    className={classes.chipPending}
                    onClick={() => onChipClick(context)}
                />
            </Show>

        </>
    )
}

export default RenderStatusChip