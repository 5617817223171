import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { sendDmMediaMessageMEOS } from '../../../../../../redux/actions/app_chat';

const UploadFile = ({ roomId, isModalOpen, handleChangeModal, selectedImg, setSelectedImg, file, setFile, attachments, roomType }) => {
    const dispatch = useDispatch();
    const [description, setDescription] = useState("");
    const is_video = file?.type?.startsWith('video/')
    const handleSaveFile = () => {
        const formData = new FormData();
        formData.append('message_text', description);
        formData.append('room_id', roomId);
        formData.append('senderId', localStorage.getItem("user"))
        if (file?.type?.startsWith('video/')) {
            formData.append('message_type', "video")
        }

        Array.from(attachments).forEach((file: any, index) => {
            formData.append('files', file); // 'files[]' indicates array of files on the backend
        });
        if (roomType == 'direct') {
            formData.append('receiverId', roomId);
            dispatch(sendDmMediaMessageMEOS(formData, 'private'));

        } else {
            dispatch(sendDmMediaMessageMEOS(formData));

        }
        handleChangeModal();
        setSelectedImg(null);
        setFile("");
        setDescription("");
    };

    return (
        <Dialog open={isModalOpen} onClose={handleChangeModal}>
            <DialogTitle>Upload File</DialogTitle>
            <DialogContent>
                {is_video ?
                    <video controls width="500">
                        <source src={selectedImg} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    :
                    <>
                        <img src={selectedImg} alt="Selected File" style={{ width: '100%', height: 'auto' }} />
                    </>
                }
                <TextField
                    fullWidth
                    label="Description"
                    variant="outlined"
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleChangeModal}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleSaveFile}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}
export default UploadFile