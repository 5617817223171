import { IAction } from '../../types/generics';
import { IProgramEnrollmentState } from '../../types/programEnrollment';
import { GET_USER_PROGRAM_ENROLLMENT, GET_USER_PROGRAM_ENROLLMENT_ERROR, GET_USER_PROGRAM_ENROLLMENT_LOADING, SET_ACTIVE_PHASE_NUMBER, CLOSE_USER_PROGRAM_ENROLLMENT_LOADING } from '../actions/programEnrollmentAction';

const initState: IProgramEnrollmentState = {
    error: null,
    loading: false,
    enrollment: null,
    activePhaseNumber: null
};

export default function (state = initState, action: IAction) {
    switch (action.type) {
        case GET_USER_PROGRAM_ENROLLMENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT:
            const response = action.payload;
            return {
                ...state,
                enrollment: response,
                activePhaseNumber: response?.status?.activeProgramPhase?.phaseNumber,
                loading: false,
                error: null,
            };
        case GET_USER_PROGRAM_ENROLLMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SET_ACTIVE_PHASE_NUMBER:
        console.log("apasdfasfdi+>>>>>>>>>>>>",action.payload)    
        return {
                ...state,
                activePhaseNumber: action.payload,
            };
        case CLOSE_USER_PROGRAM_ENROLLMENT_LOADING:
            return {
                ...state,
                loading: false,

            };
        default:
            return state;
    }
}
