import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: 'Roboto',
        color: '#2C3345',
        maxWidth: '752px',
        margin: '0 0',
        padding: '20px',
        borderRadius: '8px',
    },

    formGroup: {
        padding: 10,
        background: "white",
        borderRadius: 8,
        marginBottom: theme.spacing(3),
    },
    formGroupInner: {
        padding: 0,
        background: "white",
        borderRadius: 8,
        marginTop: '5px',
        marginBottom: '5px',
    },
    label: {
        display: 'block',
        color: "#00000099",
        fontWeight: 'normal',
        marginBottom: theme.spacing(1),
    },
    labelInner: {
        color: "#00000099",
        fontWeight: 'normal',
        marginBottom: theme.spacing(1),
    },
    sectionLabel: {
        backgroundColor: '#f0f0f0',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
        borderRadius: '4px',
    },
    textField: {
        width: '100% !important',
        padding: '10px',
        border: "none",
        outline: "none",
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
        fontSize: '16px',
        boxSizing: 'border-box',
        transition: 'border-color 0.3s ease',
        marginBottom: 0,
    },
    controlHeading1: {
        color: "#343C6A"
    },
    submitBtn: {
        background: "#3F4148",
        color: "white",
    },
    image: {
        maxWidth: "100%",
        height: 'auto'
    },
    tableScoll: {
        width: '100%',
        overflowX: 'auto',
    },
    radioButton: {
        marginRight: 0,
        justifyContent: "center",
        width: '100%',
        border: "none",
        outline: "none",
        borderBottom: '1px solid #ccc',
        borderRadius: 0,
        fontSize: '16px',
        boxSizing: 'border-box',
        transition: 'border-color 0.3s ease',
        marginBottom: 0,
        marginLeft: 0
    },
    noDataFound: {
        display: 'flex',
        height: "100vh",
        justifyContent: 'center',
        alignItems: 'center'
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)", 
    },
    nextBackbuttonGroup: {
        display: "flex",
        justifyContent: "space-between",
    },
    btnStyle: {
        cursor: 'pointer',
        height: '32px',
        marginBottom: '22px',
        display: 'flex',
        alignItems: 'center',
        marginLeft: '10px',
    },
    formSkipWrapper: {
        display: "flex",
        maxWidth: "calc(100% - 40px)",
        gap: "8px",
        justifyContent: "space-between"
    },
    btnSkip: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minWidth:"136px",
        minHeight:"20px",
        maxHeight:"40px"
    },
    disable: {
    backgroundColor: "#3F4148",
    pointerEvents: "none",
    color: "#fff",
    cursor: "not-allowed",
    borderRadius: "4px",
    opacity: 0.6,
    },
    onboardingSpinnerLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', 
        position: 'fixed', 
        top: '0',
        left: '0',
        width: '100vw', 
        height: '100vh',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: 9999,
      },
    
}));
