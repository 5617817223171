import { createConfirmation } from 'react-confirm';
import Confirmation from './Dialog';
import confirmSubmitDialog from './confirmSubmitDialog';
// create confirm function
var confirm = createConfirmation(Confirmation);

// This is optional. But I recommend to define your confirm function easy to call.
export default function (title, confirmation, options = {formAlert:false }, buttonOptions={} ) {
  if(options?.formAlert)
  {
     confirm = createConfirmation(confirmSubmitDialog);
  }
  // You can pass whatever you want to the component. It will be just passed to your Component's props
  return confirm({ title, confirmation, options,buttonOptions });
}