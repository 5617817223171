import * as React from 'react';
import { Box, } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Typography from '@material-ui/core/Typography';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { PulseLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import { colors } from '../../../constants/colors';
import { ITeamRegForm } from '../../../types/auth';
import { createTeam } from '../../../redux/actions/auth';
import { RootState } from '../../../redux/store';
import { Button, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { COUNTRIES } from '../../../constants/data';
import { Card as MuiCard } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    alignItems: 'center',
    backgroundColor: '#EDF2F7',
    padding: theme.spacing(2),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '100%',
    maxWidth: '500px',
    padding: theme.spacing(4),
    gap: '8px',
    margin: 'auto',
    boxShadow:
      'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
    borderRadius: theme.spacing(1),
    backgroundColor: '#FFF'
  },
  title: {
    fontSize: '2.75rem',
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  form: {
    maxWidth: 320,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(5),
    margin: 'auto',
  },
  titleCon: {
    paddingTop: 12,
    fontFamily: 'MontserratSemiBold',
  },
  loginTitle: {
    color: '#3b3b3b',
    fontSize: 20,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  inputStyle: {
    border: 0,
    backgroundColor: 'white',
    color: '#9d9d9d',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(1)}px 0`,
  },
  dividerLine: {
    flexGrow: 1,
    height: '1px',
    backgroundColor: theme.palette.divider,
  },
  dividerText: {
    margin: `0 ${theme.spacing(1)}px`,
    color: '#706C7C',
    fontWeight: 200,
    fontSize: 18
  },



}))
interface PropsType {
  authError: string;
  userStatus: boolean;
  createTeam: (data: ITeamRegForm) => any;
}

function Register(props: PropsType) {
  const {createTeam, userStatus } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invitation = queryParams.get('invitation');


  const onSubmit = (data: ITeamRegForm) => {
    const invitation = queryParams.get('invitation');
    if (invitation) data.invitation = invitation
    createTeam(data);
  };

  const { control, handleSubmit, errors } = useForm();

  return (<>
    <div className={classes.container}>
      <MuiCard className={classes.card}>
        <Typography variant="h1" className={classes.title}>
          <div>
            <img
              style={{ width: 120 }}
              src="../../assets/logo.png"
              alt="Logo"
            />
          </div>
        </Typography>
        <Typography className={classes.titleCon}>
          <h1 className={classes.loginTitle}>Create your team account</h1>
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="name"
              control={control}
              rules={{
                required: 'First Name is required.',
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  id="name"
                  aria-describedby="name-helper-text"
                  inputProps={{
                    'aria-label': 'name',
                  }}
                  labelWidth={0}
                  placeholder="First Name"
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="lastName"
              control={control}
              rules={{
                required: 'Last Name is required.',
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  id="lastName"
                  aria-describedby="last-name-helper-text"
                  inputProps={{
                    'aria-label': 'lastName',
                  }}
                  labelWidth={0}
                  placeholder="Last Name"
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="lastName"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="email"
              control={control}
              rules={{
                required: 'Email is required.',
              }}
              defaultValue=""
              as={
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'email',
                  }}
                  labelWidth={0}
                  placeholder="Team Account Email"
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="email"
            />
          </Grid>
          <Grid item xs={12}>
        <Controller
          className={classes.textField}
          variant="outlined"
          name="password"
          control={control}
          rules={{
            required: 'Password is required.',
          }}
          defaultValue=""
          as={
            <OutlinedInput
              name="password"
              className={classes.inputStyle}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    style={{
                      color: '#D3C3E6',
                    }}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={0}
              placeholder="Password"
            />
          }
        />
        <ErrorMessage
          as={<Typography color="error" />}
          errors={errors}
          name="password"
        />
      </Grid>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="country"
              control={control}
              rules={{
                required: 'Country is required.',
              }}
              defaultValue="Canada"
              as={
                <Select
                  labelId="country-label"
                  id="country-select-helper"
                  name='country'
                >
                  {COUNTRIES.map(({ name }, i) => (
                    <MenuItem key={i} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="password"
            />
          </Grid>
          <Box className={classes.dividerContainer}>
            <div className={classes.dividerLine}></div>
            <span className={classes.dividerText}>Invitation Code</span>
            <div className={classes.dividerLine}></div>
          </Box>
          <Grid item xs={12}>
            <Controller
              className={classes.textField}
              variant="outlined"
              name="invitation"
              control={control}
              rules={{
                required: 'Invitaion Code is required.',
              }}
              defaultValue={invitation || ''}
              as={
                <OutlinedInput
                  id="outlined-adornment-weight"
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'invitation',
                    readOnly: !!invitation,
                  }}
                  labelWidth={0}
                  placeholder="Invitaion Code"
                  className={classes.inputStyle}
                />
              }
            />
            <ErrorMessage
              as={<Typography color="error" />}
              errors={errors}
              name="invitation"
            />
          </Grid>
          <Grid>
            <Typography variant='body2'>By signing up, you agree to the MeOS user agreement and privacy policy.</Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', margin: '32px 0 8px 0' }}>
          <Button type="submit" variant='contained' color='primary' size='large' fullWidth style={{height:"50px"}}>
                Sign Up
              </Button>
          </Grid>
          <Grid>
            <Link to="/login">
              Already have an account? Sign in
            </Link>
          </Grid>
        </form>
      </MuiCard>
    </div>
  </>
  );
}
const mapStateToProps = (state: RootState) => ({
  authError: state.auth.authError,
  userStatus: state.auth.userStatus,
});

export default connect(mapStateToProps, { createTeam })(Register);



