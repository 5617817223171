import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import BookIcon from '../../../../../components/Icons/BookIcon'
import { useStyles } from '../../style'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'
import InfoIcon from '../../../../../components/Icons/InfoIcon'
import InfoModal from '../../../../../components/Modal/infoModal'
import { Description } from '@material-ui/icons'
import { setProgramInfoPopup } from '../../../../../redux/actions/programEnrollmentAction'

const UserWelcomeDiv = () => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.auth.user);
    const openModalFirstTime = useSelector((state: RootState) => state.programReducer.programInfoPopUpOpened)
    const programs = useSelector((state: RootState) => state.programReducer.singleProgram);
    useEffect(() => {
        if (!openModalFirstTime) {
            setTimeout(() => {

                setOpenModal(true)
                dispatch(setProgramInfoPopup());

            }, 1000)

        }

    }, [openModalFirstTime])
    const handleModalClick = () => {
        setOpenModal(true);
    };
    const handleCloseModal = () => {
        setOpenModal(false)
    };
    return (
        <>
            <Typography variant="h6" align="center" className={classes.welcomeTitle}>
                <BookIcon />
                <span className={classes.profileName}>Welcome, {`${user.name} ${user.lastName}`}! </span>
                <div onClick={handleModalClick} style={{ cursor: "pointer" }}>
                    <InfoIcon />
                </div>
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
                Let's get started on your course. Complete the steps below to finish this phase of the program.
            </Typography>
            {openModal && (
                <InfoModal
                    isModal={openModal}
                    closeModal={handleCloseModal}
                    title={`Welcome to the ${programs?.programName} Program !`}
                    description={'Below, you’ll find forms designed to help you dive deeper into discovering your purpose and aligning your actions with it. Each section contains multiple forms that guide you through reflective exercises and practical steps. You can work through the forms at your own pace, and if needed, you can skip a form using the option in the top right corner. Take your time to engage with each section meaningfully as you build toward living a guided and intentional life.'}
                />
            )}
        </>
    )
}

export default UserWelcomeDiv