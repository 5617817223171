import axios from 'axios';
import toastr from 'toastr';

// const devUrl = 'http://localhost:3000';
// const prodUrl = process.env.REACT_APP_API_URL;

// export const baseURL =
// process.env.NODE_ENV !== 'production' ? devUrl : prodUrl;

export const baseURL = process.env.REACT_APP_API_URL || "http://localhost:8080" ;

axios.defaults.headers.post['Content-Type'] = 'application/json';



export const INSTANCE = axios.create({
  baseURL,
});

INSTANCE.defaults.headers['X-Auth-Token'] = localStorage.getItem('rcToken');
INSTANCE.defaults.headers['X-User-Id'] = localStorage.getItem('rcUserId');

const createAxiosResponseInterceptor = () => {
  const interceptor = INSTANCE.interceptors.response.use(
    (response) => response,
    (error) => {
      // error promise 
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error);
      }

      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      INSTANCE.interceptors.response.eject(interceptor);

      return INSTANCE.post('/v1/authenticate/token', {
        refreshToken: localStorage.getItem('refreshToken'),
        user: localStorage.getItem('user'),
        team: localStorage.getItem('team'),
      })
        .then((response) => {
          onAccessTokenFetched(
            response.data.data.token,
            response.data.data.refreshToken
          );
          error.response.config.headers['Authorization'] =
            'Bearer ' + response.data.data.token;
          return INSTANCE(error.response.config);
        })
        .catch((error) => {
          console.log("errror", error)
          localStorage.clear();
          toastr.error('You are not authorized.');
          setTimeout(()=>{
            window.location.href = '/login';
          },500)
          return Promise.reject(error);
        })
        .finally(createAxiosResponseInterceptor);    
    }
  );
};

createAxiosResponseInterceptor();

export type Subscriber = (access_token: string) => any;

function onAccessTokenFetched(token: string, refreshToken: string) {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  console.log('onAccessTokenFetched - Calling subscribers');
  
  // subscribers = subscribers.filter((callback) => callback(token));
}


export function setAxiosHeadersRC(rcToken,rcUserId){
  console.log("rcToken,rcUserId", rcToken,rcUserId)
  INSTANCE.defaults.headers['X-Auth-Token'] = rcToken;
  INSTANCE.defaults.headers['X-User-Id'] = rcUserId;
}
