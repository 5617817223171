import React, { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SocketIO from "../util/socketLib";
import { addMessageRealtimeMEOS } from "../redux/actions/app_chat";

// Define context type
interface SocketContextType {
  socket: ReturnType<typeof SocketIO.getSocketCall> | null;
  joinRoom: (roomId: string) => void;
  onMessage: (eventName: string, callback: (message: any) => void) => void;
  offMessage: (eventName: string, callback: (message: any) => void) => void; // Added offMessage
}

// Create context
export const SocketContext = createContext<SocketContextType>({
  socket: null,
  joinRoom: () => {},
  onMessage: () => {},
  offMessage: () => {}, // Default no-op function
});

// SocketProvider component
export const SocketProvider: React.FC<{ userId: string | null }> = ({ userId, children }) => {
  const [socket, setSocket] = useState<ReturnType<typeof SocketIO.getSocketCall> | null>(null);
  const dispatch = useDispatch();

  // Handle incoming messages
  const handleMessageReceived = (message: any) => {
    dispatch(addMessageRealtimeMEOS(message));
  };

  // Allow components to set up custom message listeners dynamically
  const onMessage = (eventName: string, callback: (message: any) => void) => {
    socket?.on(eventName, callback);
  };

  // Allow components to remove message listeners dynamically
  const offMessage = (eventName: string, callback: (message: any) => void) => {
    socket?.off(eventName, callback);
  };

  // Join a room and setup listeners
  const joinRoom = (roomId: string) => {
    const userRoomId = localStorage.getItem("user");
    if (userRoomId) {
      SocketIO.joinRoom(userRoomId, (res) => {
        if (res?.status) {
        } else {
          console.error(`Failed to join room: ${roomId}`, res?.message);
        }
      });
    } else {
      console.error("User room ID is not available");
    }
  };

  // Initialize socket connection
  const initializeSocket = () => {
    if (!socket) {
      SocketIO.connect(
        () => {
          // console.log("Socket connected successfully");
          const currentSocket = SocketIO.getSocketCall();
          setSocket(currentSocket);

          // Listen for default messages
          currentSocket?.on("receiveMessage", handleMessageReceived);

          // Auto-join user's own room
          if (userId) {
            joinRoom(userId);
          }
        },
        (error: any) => {
          console.error("Socket connection failed", error);
        },
        userId
      );
    }
  };

  // Cleanup socket listeners
  const cleanupSocket = () => {
    if (socket) {
      socket.off("receiveMessage", handleMessageReceived);
      SocketIO.disconnect();
      console.log("Socket disconnected");
    }
  };

  useEffect(() => {
    if (userId) {
      initializeSocket();
    }

    return cleanupSocket;
  }, [userId]);

  return (
    <SocketContext.Provider value={{ socket, joinRoom, onMessage, offMessage }}>
      {children}
    </SocketContext.Provider>
  );
};

// Hook to access the SocketContext
export const useSocket = () => useContext(SocketContext);
