import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import Register from '../containers/Auth/Register';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import { useStyles } from './styles';
import RealTime from '../screens/User/RealTime';
import { IUser } from '../types/user';
import { Mixpanel } from '../util/mixpanel';
import { RootState } from '../redux/store';
import './style.css';
import { useEffect } from 'react';
import MeosChat from '../screens/User/MeosChat';
import ChatDashboard from '../screens/User/MeosChat/Dashboard';
import ClientDashboard from '../screens/User/ClientDashboard';
import BackPack from '../screens/User/Backpack';
import MyResuls from '../screens/User/MyResults';
import DefaultScreen from '../components/DefaultScreen';
import Profile from '../screens/User/Profile';
import ActionItems from '../screens/User/ActionItems';
import Sidebar from '../screens/User/Sidebar';
import { isAdmin } from '../util/helpers';
import AdminSideBar from '../screens/Admin/Sidebar';
import ProgramList from '../screens/Admin/ProgramList/List';
import ProgramDrillDown from '../screens/Admin/ProgramList/ProgramDrillDown';
import MembersList from '../screens/Admin/ProgramList/ProgramDrillDown/MemberList';
import UserRecord from '../screens/Admin/ProgramList/ProgramDrillDown/UserRecord';
import SetPassword from '../screens/OnBoarding/Auth/SetPassword';
import WelcomeOnboarding from '../screens/OnBoarding/Screens/WelcomeScreen';
import OnBoardingLayout from '../screens/OnBoarding/Screens';
import Login from '../screens/OnBoarding/Auth/Login/index';
import ProtectedRoute from './ProtectedRoute';
import { UserType } from '../constants/data';
import { SocketProvider } from '../provider/socketProvider';

interface RestrictProps {
  component: any;
  userStatus: boolean;
  exact?: boolean;
  path: string;
}
interface PropsType {
  userStatus: boolean;
  user: IUser;
}

const Navigation = (props: PropsType) => {

  useEffect(() => {
    if (props.user) {
      Mixpanel.identify(props.user._id);
      Mixpanel.people.set(props.user);
    }
  }, []);

  const isFirstTimeLogin = () => {
    return props.user?.isFirstTimeLogin || false;
  };
  
  return (
    <SocketProvider userId={localStorage.getItem("user")}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/set-password">
            {props.userStatus && isFirstTimeLogin() && (
              <SetPassword />
            )}
          </Route>

        <ProtectedRoute
          path="/onboarding"
          component={() => (
            <Switch>
              <Route exact path="/onboarding/welcome" component={WelcomeOnboarding} />
              <Route exact path="/onboarding/phase/:phaseNumber" component={OnBoardingLayout} />
              <Redirect from="/onboarding" to="/onboarding/welcome" /> </Switch>
          )}
          requiredRole={[UserType.member]}
        />

        <ProtectedRoute
          path="/admin"
          component={() => (
            <AdminLayout>
              <Switch>
                <Route exact path="/admin/dashboard" component={ProgramList} />
                <Route exact path="/admin/dashboard/program/:programId" component={ProgramDrillDown} />
                <Route exact path="/admin/dashboard/program/:programId/team/:teamId" component={MembersList} />
                <Route exact path="/admin/dashboard/userRecord/:userId" component={UserRecord} />
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </AdminLayout>
          )}
          requiredRole={[UserType.admin, UserType.teamAdmin]}
        />

        <ProtectedRoute
          path="/user"
          component={() => (
            <HomeLayout>
              <Switch>
                <Route exact path="/user/dashboard" component={ClientDashboard} />
                <Route exact path="/user/realtime" component={RealTime} />
                <Route exact path="/user/form/show" component={BackPack} />
                <Route exact path="/user/my-results" component={MyResuls} />
                <Route exact path="/user/chat-dashboard" component={ChatDashboard} />
                <Route exact path="/user/chat" component={MeosChat} />
                <Route exact path="/user/general" component={DefaultScreen} />
                <Route exact path="/user/payment" component={DefaultScreen} />
                <Route exact path="/user/subscription" component={DefaultScreen} />
                <Route exact path="/user/support" component={DefaultScreen} />
                <Route exact path="/user/profile" component={Profile} />
                <Route exact path="/user/action-items" component={ActionItems} />
                <Redirect from="/user" to="/user/dashboard" />
              </Switch>
            </HomeLayout>
          )}
          requiredRole={[UserType.member, UserType.client, UserType.coach, UserType.teamAdmin]}
        />
          <Route exact path="/" render={() => {
            return props.userStatus ? <Redirect to="/user/dashboard" /> : <Redirect to="/login" />;
          }} />

          <Route render={() => <Redirect to="/login" />} />

        </Switch>
      </BrowserRouter>
    </SocketProvider>
  );
};

interface HomeProps {
  children?: ReactNode;
}

function HomeLayout(props: HomeProps) {
  const classes = useStyles();
  return (
    <div className='wrapper'>
      <Sidebar />
      <div className='main_cnt'>
        {props.children}
      </div>
    </div>
  );
}

function AdminLayout(props: HomeProps) {
  const history = useHistory();
  useEffect(() => {
    if (!isAdmin()) {
      history.push(`/user/dashboard`);
    }
  }, []);

  return (
    <div className='wrapper'>
      <AdminSideBar />
      <div className='main_cnt'>
        {props.children}
      </div>
    </div>
  );
}

const mapStateToProps = (store: RootState) => ({
  userStatus: store.auth.userStatus,
  user: store.auth.user,
  activePhaseNumber: store.programEnrollmentReducer.activePhaseNumber
});

export default connect(mapStateToProps)(Navigation);
