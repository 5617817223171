import { getProgramContextListApi, getProgramFormContextDataApi, getProgramFormStructureApi, getProgramMyBackpackApi, submitProgramFormApi } from '../../api';
import toastr from "toastr";
import { getUserProgramEnrollmentData, closeEnrollmentLoading, getprogramEnrollmentLoading } from './programEnrollmentAction';
export const GET_PROGRAM_FORM_STRUCTURE = 'GET_PROGRAM_FORM_STRUCTURE';
export const GET_PROGRAM_FORM_STRUCTURE_CACHE = 'GET_PROGRAM_FORM_STRUCTURE_CACHE';
export const GET_PROGRAM_FORM_STRUCTURE_ERROR = 'GET_PROGRAM_FORM_STRUCTURE_ERROR';
export const GET_PROGRAM_FORM_STRUCTURE_LOADING = 'GET_PROGRAM_FORM_STRUCTURE_LOADING';
export const GET_PROGRAM_FORM_CONTEXT_DATA = 'GET_PROGRAM_FORM_CONTEXT_DATA';
export const GET_PROGRAM_FORM_CONTEXT_CACHE = 'GET_PROGRAM_FORM_CONTEXT_DATA_CACHE';
export const GET_PROGRAM_FORM_CONTEXT_DATA_ERROR = 'GET_PROGRAM_FORM_CONTEXT_DATA_ERROR';
export const GET_PROGRAM_FORM_CONTEXT_DATA_LOADING = 'GET_PROGRAM_FORM_CONTEXT_DATA_LOADING';
export const GET_PROGRAM_CONTEXT_LIST = 'GET_PROGRAM_CONTEXT_LIST';
export const GET_PROGRAM_CONTEXT_LIST_ERROR = 'GET_PROGRAM_CONTEXT_LIST_ERROR';
export const GET_PROGRAM_CONTEXT_LIST_LOADING = 'GET_PROGRAM_CONTEXT_LIST_LOADING';
export const RESET_USER_FORM_DATA = "RESET_USER_FORM_DATA";
export const RESET_PROGRAM_FORM_STRUCTURE = "RESET_PROGRAM_FORM_STRUCTURE";
export const OPEN_PROGRAM_FORM_MODAL = "OPEN_PROGRAM_FORM_MODAL";
export const CLOSE_PROGRAM_FORM_MODAL = "CLOSE_PROGRAM_FORM_MODAL";


export const getProgramFormStructure = (programId: string, sourceFormId: string, userId: string) => {
    return async (dispatch: any) => {
        dispatch(resetProgramFormStructure())
        dispatch(getProgramFormStructureLoading());
        try {
            const res: any = await getProgramFormStructureApi(programId, sourceFormId, userId);
            res.formDefinition.details.programId = programId
            res.formDefinition.details.sourceFormId = sourceFormId
            dispatch(getProgramFormStructureSuccess(res));
            dispatch(getProgramFormStructureCache(res));
        } catch (error) {
            if (error.response) {
                dispatch(getProgramFormStructureError(error.response.data.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramFormStructureError(error.message || 'An error occurred'));
        }
    };
};

export const submitProgramForm = ({
    programId,
    sourceFormId,
    userId,
    contextId,
    year,
    payload,
}: {
    programId: string;
    sourceFormId: string;
    userId: string;
    contextId: string;
    year?: string;
    payload: any;
}) => {
    return async (dispatch: any) => {
        try {
            dispatch(getprogramEnrollmentLoading());
            const response: any = await submitProgramFormApi(programId, sourceFormId, userId, contextId, payload);
            if (response) {
                if (year && year !== undefined) {
                    dispatch(getProgramListContextData(programId, userId, year));
                }
                dispatch(resetUserFormData());
                dispatch(getUserProgramEnrollmentData(programId, userId))
                dispatch(closeEnrollmentLoading());
                dispatch(closeProgramFormModal());
                if(!payload.data.skipped)
                {
                    toastr.success("Form submitted successfully");
                }   
            }
        } catch (error) {
            toastr.error("Error", "Failed to submit the form. Please try again.");
            dispatch(closeEnrollmentLoading());

        }
    };
}

export const getProgramFormContextData = (programId: string, sourceFormId: string, userId: string, contextId: string) => {
    return async (dispatch: any) => {
        dispatch(getProgramFormContextDataLoading());
        try {
            let res: any = await getProgramFormContextDataApi(programId, sourceFormId, userId, contextId);
            dispatch(getProgramFormContextDataSuccess(res?.data));
            dispatch(getProgramFormContextDataCache(res.data));

        } catch (error) {
            if (error?.response) {
                dispatch(getProgramFormContextDataError(error.response?.data?.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramFormContextDataError(error.message || 'An error occurred'));
        }
    };
};

export const getProgramListContextData = (programId: string, userId: string, year: string) => {
    return async (dispatch: any) => {
        dispatch(getProgramContextListLoading());
        try {
            const res: any = await getProgramContextListApi(programId, userId, year);
            dispatch(getProgramContextListSuccess(res));
        } catch (error) {
            if (error?.response) {
                dispatch(getProgramContextListError(error.response?.data?.message || 'An error occurred'));
                return;
            }
            dispatch(getProgramContextListError(error.message || 'An error occurred'));
        }
    };
};

export const getProgramFormStructureLoading = () => ({
    type: GET_PROGRAM_FORM_STRUCTURE_LOADING,
});

export const openProgramFormModal = () => ({
    type: OPEN_PROGRAM_FORM_MODAL,
});

export const closeProgramFormModal = () => ({
    type: CLOSE_PROGRAM_FORM_MODAL,
});


export const getProgramFormStructureSuccess = (payload) => ({
    type: GET_PROGRAM_FORM_STRUCTURE,
    payload,
});

const getProgramFormStructureCache = (payload) => ({
    type: GET_PROGRAM_FORM_STRUCTURE_CACHE,
    payload,
});


const getProgramFormStructureError = (error: any) => ({
    type: GET_PROGRAM_FORM_STRUCTURE_ERROR,
    payload: error,
});

const getProgramFormContextDataLoading = () => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA_LOADING,
});

const getProgramFormContextDataSuccess = (payload) => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA,
    payload,
});

const getProgramFormContextDataCache = (payload) => ({
    type: GET_PROGRAM_FORM_CONTEXT_CACHE,
    payload,
});


const getProgramFormContextDataError = (error: any) => ({
    type: GET_PROGRAM_FORM_CONTEXT_DATA_ERROR,
    payload: error,
});

const getProgramContextListLoading = () => ({
    type: GET_PROGRAM_CONTEXT_LIST_LOADING,
});

const getProgramContextListSuccess = (payload) => ({
    type: GET_PROGRAM_CONTEXT_LIST,
    payload,
});

const getProgramContextListError = (error: any) => ({
    type: GET_PROGRAM_CONTEXT_LIST_ERROR,
    payload: error,
});

const resetUserFormData = () => ({
    type: RESET_USER_FORM_DATA,
})

export const resetProgramFormStructure = () => ({
    type: RESET_PROGRAM_FORM_STRUCTURE
});

