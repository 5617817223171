import { GET_ALL_PROGRAMS, GET_ALL_PROGRAMS_ERROR, GET_ALL_PROGRAMS_LOADING, GET_PROGRAM_ACTION_ITEMS, GET_PROGRAM_ACTION_ITEMS_ERROR, GET_PROGRAM_ACTION_ITEMS_LOADING, GET_PROGRAM_ANNOUNCEMENTS, GET_PROGRAM_ANNOUNCEMENTS_ERROR, GET_PROGRAM_ANNOUNCEMENTS_LOADING, GET_PROGRAM_FORM_CONTEXT_USER_INFO, GET_PROGRAM_FORM_CONTEXT_USER_INFO_ERROR, GET_PROGRAM_FORM_CONTEXT_USER_INFO_LOADING, GET_PROGRAM_TEAM_USERS, GET_PROGRAM_TEAM_USERS_ERROR, GET_PROGRAM_TEAM_USERS_LOADING, GET_PROGRAM_TEAMS, GET_PROGRAM_TEAMS_ERROR, GET_PROGRAM_TEAMS_LOADING, GET_SINGLE_PROGRAM, GET_SINGLE_PROGRAM_ERROR, GET_SINGLE_PROGRAM_LOADING, REMOVE_TEAM, REMOVE_TEAM_ERROR, REMOVE_TEAM_LOADING, RESET_PROGRAM_FORM_CONTEXT, SET_PROGRAM_FORM_CONTEXT, UPDATE_PROGRAM_FORM, UPDATE_PROGRAM_FORM_ERROR, UPDATE_PROGRAM_FORM_LOADING } from './../actions/programAction';
import { IAction } from '../../types/generics';
import { IProgramState } from '../../types/program';
import { GET_PROGRAM_BACKPACK_DATA, GET_PROGRAM_BACKPACK_DATA_ERROR, GET_PROGRAM_BACKPACK_DATA_LOADING, GET_PROGRAM_FORMS_LIST, GET_PROGRAM_FORMS_LIST_ERROR, GET_PROGRAM_FORMS_LIST_LOADING } from '../actions/programAction';
import { SAVE_TEAM, SAVE_TEAM_ERROR, SAVE_TEAM_LOADER } from '../actions/programAction';
import { PROGRAM_INFO_POPUP_OPENED } from '../actions/programEnrollmentAction';

const initState: IProgramState = {
    list: [],
    backpackList: null,
    contexts: null,
    allPrograms: [],
    singleProgram: null,
    teamList: [],
    error: {},
    loading: false,
    contextError: '',
    contextLoading: false,
    announcementList: null,
    announcementError: '',
    announcementLoading: false,
    programFormContextInfo: null,
    programFormContextUserInfo: null,
    programFormContextUserInfoError: '',
    programFormContextUserInfoLoading: false,
    allProgramsLoading: false,
    allProgramsError: '',
    singleProgramLoading: false,
    singleProgramError: '',
    updateLoading: false,
    updateError: null,
    teamListError: '',
    teamListLoading: false,
    saveTeamsError: '',
    saveTeamsLoading: false,
    teamUsersList: [],
    teamUsersListError: '',
    teamUsersListLoading: false,
    programInfoPopUpOpened: false,
};

export default function (state = initState, action: IAction) {
    switch (action.type) {
        case GET_PROGRAM_FORMS_LIST_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PROGRAM_FORMS_LIST:
            return {
                ...state,
                list: action.payload,
                loading: false,
                error: null,
            };
        case PROGRAM_INFO_POPUP_OPENED:
            return {
                ...state,
                programInfoPopUpOpened: true,

            };
        case GET_PROGRAM_FORMS_LIST_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_PROGRAM_BACKPACK_DATA_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_PROGRAM_BACKPACK_DATA:
            return {
                ...state,
                backpackList: action.payload,
                loading: false,
                error: null,
            };
        case GET_PROGRAM_BACKPACK_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case GET_PROGRAM_ACTION_ITEMS_LOADING:
            return {
                ...state,
                contextLoading: true,
                contextError: null,
            };
        case GET_PROGRAM_ACTION_ITEMS:
            return {
                ...state,
                contexts: action.payload,
                contextLoading: false,
                contextError: null,
            };
        case GET_PROGRAM_ACTION_ITEMS_ERROR:
            return {
                ...state,
                contextLoading: false,
                contextError: action.payload,
            };
        case GET_PROGRAM_ANNOUNCEMENTS_LOADING:
            return {
                ...state,
                announcementLoading: true,
                announcementError: null,
            };
        case GET_PROGRAM_ANNOUNCEMENTS:
            return {
                ...state,
                announcementList: action.payload,
                announcementLoading: false,
                announcementError: null,
            };
        case GET_PROGRAM_ANNOUNCEMENTS_ERROR:
            return {
                ...state,
                announcementLoading: false,
                announcementError: action.payload,
            };
        case SET_PROGRAM_FORM_CONTEXT:
            return {
                ...state,
                programFormContextInfo: action.payload,
            };
        case RESET_PROGRAM_FORM_CONTEXT:
            return {
                ...state,
                programFormContextInfo: null
            };
        case GET_PROGRAM_FORM_CONTEXT_USER_INFO_LOADING:
            return {
                ...state,
                programFormContextUserInfoError: null,
                programFormContextUserInfoLoading: true,
            };
        case GET_PROGRAM_FORM_CONTEXT_USER_INFO:
            return {
                ...state,
                programFormContextUserInfo: action.payload.data,
                programFormContextUserInfoLoading: false,
                programFormContextUserInfoError: null,
            };
        case GET_PROGRAM_FORM_CONTEXT_USER_INFO_ERROR:
            return {
                ...state,
                programFormContextUserInfoError: action.payload,
                programFormContextUserInfoLoading: false,
            };
        case GET_ALL_PROGRAMS_LOADING:
            return {
                ...state,
                allProgramsLoading: true,
                allProgramsError: null,
            };
        case GET_ALL_PROGRAMS:
            return {
                ...state,
                allPrograms: action.payload,
                allProgramsLoading: false,
                allProgramsError: null,
            };
        case GET_ALL_PROGRAMS_ERROR:
            return {
                ...state,
                allProgramsLoading: false,
                allProgramsError: action.payload,
            };
        case GET_SINGLE_PROGRAM_LOADING:
            return {
                ...state,
                singleProgramLoading: true,
                singleProgramError: null,
            };
        case GET_SINGLE_PROGRAM:
            return {
                ...state,
                singleProgram: action.payload,
                singleProgramLoading: false,
                singleProgramError: null,
            };
        case GET_SINGLE_PROGRAM_ERROR:
            return {
                ...state,
                singleProgramLoading: false,
                singleProgramError: action.payload,
            };
        case UPDATE_PROGRAM_FORM_LOADING:
            return {
                ...state,
                updateLoading: true,
                updateError: null,
            };
        case UPDATE_PROGRAM_FORM:
            return {
                ...state,
                updateLoading: false,
                singleProgram: {
                    ...action.payload?.data
                },
                updateError: null,
            };

        case UPDATE_PROGRAM_FORM_ERROR:
            return {
                ...state,
                updateLoading: false,
                updateError: action.payload,
            };
        case GET_PROGRAM_TEAMS_LOADING:
            return {
                ...state,
                teamListLoading: true,
                teamListError: null,
            };
        case GET_PROGRAM_TEAMS:
            return {
                ...state,
                teamList: action.payload?.data,
                teamListLoading: false,
                teamListError: null,
            };
        case GET_PROGRAM_TEAMS_ERROR:
            return {
                ...state,
                teamListLoading: false,
                teamListError: action.payload,
            };
        case REMOVE_TEAM_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case REMOVE_TEAM:
            return {
                ...state,
                loading: false,
                teamList: state.teamList.filter((team) => team._id !== action.payload),
            };
        case REMOVE_TEAM_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SAVE_TEAM:
            return {
                ...state,
                teamList: state.teamList.map(team =>
                    team._id === action.payload._id ? { ...team, ...action.payload } : team
                ),
            };
        case SAVE_TEAM_ERROR:
            return {
                ...state,
                saveTeamsError: action.payload,
            };
        case SAVE_TEAM_LOADER:
            return {
                ...state,
                saveTeamsLoading: action.payload,
            };
        case GET_PROGRAM_TEAM_USERS_LOADING:
            return {
                ...state,
                teamUsersListLoading: true,
                teamUsersListError: null,
            };
        case GET_PROGRAM_TEAM_USERS:
            return {
                ...state,
                teamUsersList: action.payload,
                teamUsersListLoading: false,
                teamUsersListError: null,
            };
        case GET_PROGRAM_TEAM_USERS_ERROR:
            return {
                ...state,
                teamUsersListLoading: false,
                teamUsersListError: action.payload,
            };
        default:
            return state;
    }
}
