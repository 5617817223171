import { makeStyles, Theme } from '@material-ui/core/styles';
import { deepOrange, deepPurple, blue, green, red, pink, yellow, grey } from '@material-ui/core/colors';

export const useChatStyle =  makeStyles((theme: Theme) => ({
    paperWrapper: {
        marginLeft: "280px",
        padding: '0',
        height: 'calc(100vh - 68px)',
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 280px)',
        [theme.breakpoints.down('md')]: {
            marginLeft: "280px",
            width: 'calc(100% - 200px)',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0",
            width: '100%',
        },
    },
    chatWrapper: {
        padding: '1rem',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        // height: 'calc(100vh - 100px)',
                height: 'calc(100vh - 130px)',
        boxShadow: "none",
        borderRadius: 0,
        background: 'transparent'
    },
    messageWrapper: {
      display: "flex",
      justifyContent: "space-between",
    },
    

    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
      },
      purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
      },
      blue: {
        color: theme.palette.getContrastText(blue[500]),
        backgroundColor: blue[500],
      },
      green: {
        color: theme.palette.getContrastText(green[500]),
        backgroundColor: green[500],
      },
      red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
      },
      pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      yellow: {
        color: theme.palette.getContrastText(yellow[500]),
        backgroundColor: yellow[500],
      },
      grey: {
        color: theme.palette.getContrastText(grey[500]),
        backgroundColor: grey[500],
      },
      
      loader: {
        position: 'absolute',
        top:'50%',
        left: '50%',
      },



}));
export const getAvatarBackgroundColor = (name) => {
    if(!name)return
    const letter = name?.charAt(0)?.toUpperCase();
    switch (letter) {
      case 'A': return 'orange';
      case 'B': return 'purple';
      case 'C': return 'blue';
      case 'D': return 'green';
      case 'E': return 'red';
      case 'F': return 'pink';
      case 'G': return 'yellow';
      case 'H': return 'grey';
      case 'I': return 'orange';
      case 'J': return 'purple';
      case 'K': return 'blue';
      case 'L': return 'green';
      case 'M': return 'red';
      case 'N': return 'pink';
      case 'O': return 'yellow';
      case 'P': return 'grey';
      case 'Q': return 'orange';
      case 'R': return 'purple';
      case 'S': return 'blue';
      case 'T': return 'green';
      case 'U': return 'red';
      case 'V': return 'pink';
      case 'W': return 'yellow';
      case 'X': return 'grey';
      case 'Y': return 'orange';
      case 'Z': return 'purple';
      default: return 'blue'; 
    }
  };