import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    MenuItem,
    TextField,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CustomDialogTitle from './DialogTitle';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useStyles } from '../../style';
import { ITeam } from '../../../../../types/team';
import { COUNTRIES } from '../../../../../constants/data';

type UpdateTeamDialogProps = {
    open: boolean;
    handleClose: () => void;
    data: ITeam | null;
    onSave: (updatedData) => void;
};

const UpdateTeamDialog = (props: UpdateTeamDialogProps) => {
    const theme = useTheme();
    const classes = useStyles();
    const { open, handleClose, data, } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { control, handleSubmit, errors, reset } = useForm({
        defaultValues: data || {},
    });

    useEffect(() => {
        reset(data);
    }, [data, reset]);
    const onSubmit = (formData) => {
        props.onSave({
            ...data,
            ...formData
        });
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullScreen={fullScreen} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomDialogTitle id="form-dialog-title">Edit Team</CustomDialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" style={{ gap: 20 }}>
                        <DialogContentText>
                            Update the details of the form below. Make necessary changes and click "Save" to update the form.
                        </DialogContentText>
                        <Grid item xs={12}>
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: 'Team is required.' }}
                                defaultValue=""
                                as={
                                    <TextField
                                        id="outlined-label"
                                        aria-describedby="outlined-label-helper-text"
                                        inputProps={{
                                            'aria-label': 'label',
                                        }}
                                        placeholder="Team Name"
                                        label="Team Name"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.name}
                                    />
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="name"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Controller
                                variant="outlined"
                                name="country"
                                control={control}
                                rules={{
                                    required: 'Country is required.',
                                }}
                                defaultValue="United States"
                                as={
                                    <TextField
                                        id="country-select-helper"
                                        variant="outlined"
                                        fullWidth
                                        select
                                        error={!!errors.country}
                                    >
                                        {COUNTRIES.map(({ name }, i) => (
                                            <MenuItem key={i} value={name}>
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                }
                            />
                            <ErrorMessage
                                as={<Typography color="error" />}
                                errors={errors}
                                name="country"
                            />
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(UpdateTeamDialog);
