import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, List, ListItem, makeStyles, Toolbar, Typography } from '@material-ui/core';
import { BorderColor, CheckCircleOutline, ErrorOutline, ExpandMore, Lock } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';
import HeaderWithProgressBar from '../components/HeaderProgessBar';
import ActionButtons from '../components/ActionButtons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import UserWelcomeDiv from '../components/UserWelcomeDiv';
import ActionItemFormModal from '../components/ActionItemFormModal';
import RenderStatusChip from '../components/RenderChipButton';

function OnBoardingScreen1({ phaseNumber, markCompleted, phase, groupedContexts, handleChipClick }) {
    const lastExpandedFromStorage = localStorage.getItem('lastExpandedAccordion');
    let lastExpanded: number | null = lastExpandedFromStorage !== null ? parseInt(lastExpandedFromStorage, 10) : null;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState<number | false | string>(lastExpanded | 0);
    const onNext = () => {
        markCompleted(1, 2, "completed");
    };
    useEffect(() => {
        if (lastExpanded !== null && !isNaN(lastExpanded)) {
            setExpanded(lastExpanded);
        }
    }, []);
    const currentProgress = Math.floor((phaseNumber / 3) * 100);
    const handleChange = (index) => {
        setExpanded(index)
        localStorage.setItem('lastExpandedAccordion', index);
    }
    return (
        <Box className={classes.root}>

            <HeaderWithProgressBar logoText="Logo" progressValue={currentProgress} />
            <div className={classes.container}>
                <Box className={classes.boxWrapper}>
                    <UserWelcomeDiv />
                    <Box mt={3}>
                        <Typography variant='h6' style={{ fontWeight: 700, display: 'flex', alignItems: 'center', gap: 6, marginBottom: '10px' }}>
                            {phase?.phaseName} <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="10" cy="10" r="9.5" stroke="#FF9C00" />
                                <path d="M9.61554 3.79417C9.41271 3.87768 9.12971 4.11255 8.99764 4.31088C8.78066 4.63448 8.72878 4.94242 8.75708 5.63137C8.8467 7.5312 9.07782 11.7067 9.10141 11.7954C9.20989 12.2025 9.59195 12.5 10.007 12.5C10.2995 12.5 10.4693 12.4321 10.6438 12.2495C10.9268 11.9572 10.9174 12.0407 11.106 8.48112C11.2947 5.05725 11.2947 4.82238 11.1249 4.46224C11.0211 4.24303 10.7712 3.97685 10.5542 3.86202C10.3372 3.75242 9.81364 3.71066 9.61554 3.79417Z" fill="#FF9C00" />
                                <path d="M9.75278 13.7837C9.086 13.9056 8.63354 14.5808 8.77642 15.2465C8.99074 16.2592 10.2386 16.5968 10.9387 15.8326C11.0578 15.7013 11.1768 15.5184 11.2054 15.4247C11.2721 15.1856 11.2626 14.773 11.1864 14.5573C11.0482 14.1775 10.6815 13.8587 10.3195 13.7978C10.2148 13.779 10.1005 13.7603 10.0624 13.7509C10.0243 13.7462 9.88613 13.7603 9.75278 13.7837Z" fill="#FF9C00" />
                            </svg>
                        </Typography>
                        {Object.keys(groupedContexts).map((groupName, index) => (
                            <Accordion key={index} className={classes.accWrapper} expanded={expanded === index} onChange={() => handleChange(index)}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    <Box display="flex" alignItems="center" width="100%" justifyContent="space-between">
                                        <Typography className={classes.accordianTitle} variant="h4">{groupName && groupName !== "Ungrouped" && (groupName || 'Group')}</Typography>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {groupedContexts[groupName].map((context, subIndex) => (
                                            <ListItem key={subIndex}>
                                                <Box display={'flex'} flexDirection={'column'}>
                                                    <Typography className={classes.formLabel} variant='h6'>{context.label}</Typography>
                                                    <Typography className={classes.formDescription} variant='caption'>{context.description}</Typography>
                                                </Box>
                                                <RenderStatusChip context={context} onChipClick={handleChipClick} groupedContexts={groupedContexts} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Box>
            </div>
            <ActionButtons
                phaseNumber={1}
                learnMoreText='Learn More'
                nextText='Next Goal Setting'
                onLearnMore={() => console.log('Learn More clicked')}
                onNext={() => onNext()}
                isDisabledNext={false}
            // isDisabledNext={userEnrollment?.status?.activeProgramPhase?.phaseNumber !== 2 ? true : false}
            />

        </Box>
    );
}

export default OnBoardingScreen1;
