import React from 'react'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ButtonAppBar from '../../../../components/AppBar';
import HeaderNotificationIcon from '../../../../components/HeaderNotification';
import { useHistory } from 'react-router';
import { useStyles } from '../style';
import { Box, Typography } from '@material-ui/core';
import TeamList from './TeamList';
import AnnouncementList from './Announcement';
import ProgramDetails from './ProgramDetails';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';


interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const ProgramDrillDown = () => {
    const classes = useStyles();
    const history = useHistory();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const {singleProgram} = useSelector((state: RootState) => state.programReducer)


    return (
        <>
            <ButtonAppBar title={singleProgram?.programName} back={() => history.go(-1)} rightIcon={<HeaderNotificationIcon />} />
            <div className={classes.container}>
                <Tabs
                    className={classes.tabsDiv}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Program Details" {...a11yProps('1')} />
                    <Tab label="Program Teams" {...a11yProps('1')} />
                    <Tab label="Program Announcement" {...a11yProps('1')} />
                </Tabs>

                <TabPanel value={value} index={0}>
                    <ProgramDetails/>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <TeamList/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <AnnouncementList/>
                    
                </TabPanel>



            </div>
        </>
    )
}

export default ProgramDrillDown;

