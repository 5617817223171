import { io, Socket } from "socket.io-client";

let isConnectedWithSocket = false;
// const LOG = process.env.REACT_APP_ENV === DEV_ENV;
const LOG = true;

class SocketIO {
    on(arg0: string, arg1: () => void) {
      throw new Error("Method not implemented.");
    }
    socket: Socket | null = null;

    connect(connectCallBack, connectionErrorCallBack, userId) {
        this.socket = io(`${process.env.REACT_APP_API_URL}`, {
            query: { userId },
        });

        this.socket.on("connect", () => {
            // console.log("Socket connected!");
            if (connectCallBack) connectCallBack();
        });

        this.socket.on("connect_error", () => {
            if (connectionErrorCallBack) connectionErrorCallBack();
        });
    }

    joinRoom(userId: string, callback: (data: any) => void) {
        this.socket?.emit("join-room", { userId: localStorage.getItem("user") }, (data) => {
            if (callback) callback(data);
        });
    }

    onMessageRecieved(callback: (data: any) => void) {
        if (this.socket) {
            this.socket.on("receiveMessage", callback);
        }
    }

    getSocketCall() {
        return this.socket;
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}


export default new SocketIO();
