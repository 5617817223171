import { Box, Button, Card as MuiCard, CardContent, Grid, LinearProgress, List, ListItem, makeStyles, Toolbar, Typography, Card, FormControlLabel, Checkbox } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import OnBoardingPhase1Icon from '../../../../components/Icons/OnBoardingPhase1Icon';
import OnBoardingPhase2Icon from '../../../../components/Icons/OnBoardingPhase2Icon';
import OnBoardingPhase3Icon from '../../../../components/Icons/OnBoardingPhase3Icon';
import HeaderWithProgressBar from '../components/HeaderProgessBar';
import TickIcon from '../../../../components/Icons/TickIcon';
import GreenTickIcon from '../../../../components/Icons/GreenTickIcon';
import { useStyles } from '../style';
import ActionButtons from '../components/ActionButtons';
import { useDispatch } from 'react-redux';
import { setActivePhaseNumber } from '../../../../redux/actions/programEnrollmentAction';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         backgroundColor: '#f5f7fa',
//         minHeight: '100vh',
//     },
//     header: {
//         justifyContent: 'center',
//         '& .MuiTypography-h4': {
//             fontSize: '2.75rem',
//             textAlign: "center",
//             fontWeight: 700,
//             marginBottom: "0",
//             lineHeight: "unset"
//         }
//     },
//     progressBar: {
//         margin: theme.spacing(2, 0),
//         height: '10px',
//         borderRadius: 0,
//         backgroundColor: '#fff',
//         '& .MuiLinearProgress-barColorPrimary': {
//             backgroundColor: '#7CA0BB',
//             borderRadius: 6
//         }
//     },
//     section: {
//         marginBottom: theme.spacing(2),
//         borderRadius: '8px',
//     },
//     sectionHeader: {
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         marginBottom: theme.spacing(1),
//     },
//     chipCompleted: {
//         color: '#47AF59',
//         background: 'transparent'
//     },
//     chipPending: {
//         backgroundColor: '#fff3cd',
//         color: '#856404',
//     },
//     chipIncomplete: {
//         backgroundColor: '#f8d7da',
//         color: '#721c24',
//     },
//     actionButtons: {
//         display: 'flex',
//         justifyContent: 'flex-end',
//         gap: `${theme.spacing(2)}px`,
//         marginTop: theme.spacing(4),
//         padding: `${theme.spacing(3)}px 0`,
//         borderTop: '1px solid #717171',
//         width: '100%',
//         maxWidth: '1100px',
//         margin: '0 auto'
//     },
//     avatar: {
//         width: '40px',
//         height: '40px',
//         borderRadius: '50%',
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         backgroundColor: '#6c757d',
//         color: '#fff',
//         marginLeft: theme.spacing(1),
//     },
//     boxWrapper: {
//         width: '100%',
//         maxWidth: "930px",
//         margin: '0 auto',
//         padding: `${theme.spacing(3)}px 0`
//     },
//     welcomeTitle: {
//         display: 'flex',
//         justifyContent: "space-between",
//         alignItems: 'center'
//     },
//     accordianTitle: {
//         fontWeight: 700,
//         color: "#1E1E1E"
//     },
//     accWrapper: {
//         marginBottom: theme.spacing(2),
//         outline: 'none',
//         borderRadius: 8,
//         padding: '0 15px',
//         boxShadow: 'none',
//         '&::before': {
//             background: 'transparent'
//         },
//         '& .MuiList-root': {
//             width: '100%',
//             '& .MuiListItem-root': {
//                 flexDirection: 'row',
//                 justifyContent: 'space-between',
//                 width: '100%',
//                 padding: 0,
//                 paddingBottom: theme.spacing(2),
//                 marginBottom: theme.spacing(2),
//                 borderBottom: '1px solid #F1F1F1',
//             },
//             '& .MuiListItem-root:last-child': {
//                 borderBottom: 'transparent',
//                 paddingBottom: theme.spacing(0),
//                 marginBottom: theme.spacing(0),
//             }
//         }
//     },
//     formLabel: {
//         display: 'block',
//         width: '100%',
//         color: '#1E1E1E',
//         fontSize: 16,
//         marginBottom: theme.spacing(0.5)
//     },
//     formDescription: {
//         display: 'block',
//         width: '100%',
//         color: '#686677',
//         fontSize: 14
//     },
//     learnMoreBtn: {
//         background: 'transparent',
//         color: '#1F2937',
//         textTransform: 'capitalize',
//         textDecoration: 'underline',
//         '&:hover': {
//             background: "transparent"
//         },
//         '& .MuiButton-label': {
//             fontWeight: 600,
//         }
//     },
//     nextBtn: {
//         background: '#1F2937',
//         color: '#fff',
//         textTransform: 'capitalize',
//         borderRadius: 6,
//         padding: '11px 35px',
//         '&:hover': {
//             background: '#2c3b50'
//         },
//     },
//     card: {
//         display: 'flex',
//         flexDirection: 'column',
//         alignSelf: 'center',
//         width: '100%',
//         maxWidth: '1000px',
//         padding: theme.spacing(4),
//         gap: '16px',
//         margin: 'auto',
//         boxShadow:
//             'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
//         borderRadius: theme.spacing(1),
//         backgroundColor: '#FFF'
//     },
//     container: {
//         display: 'flex',
//         justifyContent: 'center',
//         minHeight: '100vh',
//         alignItems: 'center',
//         backgroundColor: '#EDF2F7',
//         padding: theme.spacing(2),
//     },
//     title: {
//         fontSize: '2.75rem',
//         fontWeight: 700,
//         textAlign: 'center',
//     },
//     subTitle: {
//         color: '#100F14',
//         fontSize: '1.45rem',
//         fontWeight: 600,
//         textAlign: 'center',
//     },
//     titlePara: {
//         width: '75%',
//         margin: 'auto',
//         fontSize: '1rem',
//         color: '#49475A',
//         textAlign: 'center',
//         marginBottom: theme.spacing(2),
//     },
//     form: {
//         display: 'flex',
//         flexDirection: 'column',
//         gap: '20px',
//         '& .MuiOutlinedInput-root': {
//             borderRadius: 6
//         },
//         '& .MuiFormLabel-root': {
//             color: '#9794AA',
//             marginBottom: theme.spacing(1),
//         }
//     },
//     submitBtn: {
//         width: '100%',
//         maxWidth: '295px',
//         background: '#1F2937',
//         color: '#fff',
//         textTransform: 'capitalize',
//         borderRadius: 6,
//         padding: '11px 11px',
//         marginTop: theme.spacing(2),
//         marginBottom: theme.spacing(2),
//         '&:hover': {
//             background: '#2c3b50'
//         },
//     },
//     stepCard: {
//         border: '1px solid #ddd',
//         borderRadius: '8px',
//         textAlign: 'center',
//         padding: theme.spacing(2),
//         boxShadow: 'none',
//         '&:hover': {
//             boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
//         },
//     },
//     icon: {
//         width: '50px',
//         height: '50px',
//         marginBottom: theme.spacing(1),
//     },
//     stepTitle: {
//         fontWeight: 'bold',
//         marginTop: theme.spacing(1),
//     },
//     stepDescription: {
//         color: '#6c757d',
//     },

// }));

const OnboardingScreen3 = ({ phaseNumber, markCompleted }) => {

    const history = useHistory();
    const classes = useStyles();
      const dispatch = useDispatch();
    

    const onNext = () => {
        dispatch(setActivePhaseNumber(4));
        markCompleted(3, -1, 'completed');
    }

    const onBack = () => {
        markCompleted(2, 2, null)
    }

    const steps = [
        {
            id: 1,
            icon: <OnBoardingPhase1Icon />,
            title: 'Step 1',
            description: 'Develop Your Purpose',
        },
        {
            id: 2,
            icon: <OnBoardingPhase2Icon />,
            title: 'Step 2',
            description: 'Define Long Term Goals',
        },
        {
            id: 3,
            icon: <OnBoardingPhase3Icon />,
            title: 'Step 3',
            description: 'Establish Balance & Focus',
        }
    ]

    return (
        <>
            <Box className={classes.root}>

                <HeaderWithProgressBar logoText='Logo' progressValue={100} />

                <div className={classes.container}>
                    <MuiCard className={classes.card}>
                        <Typography variant="h1" className={classes.title}>
                            <TickIcon />
                        </Typography>
                        <Typography className={classes.subTitle}>
                            Congratulations, you are ready to configure your program
                        </Typography>

                        <Grid container spacing={3}>
                            {steps.map((step) => (
                                <Grid item xs={12} sm={6} md={4} key={step.id}>
                                    <Card className={classes.stepCard}>
                                        <CardContent>
                                            <Box>
                                                {step.icon}
                                            </Box>
                                            <Typography variant="h6" className={classes.stepTitle}>
                                                {step.title}
                                            </Typography>
                                            <Typography variant="body2" className={classes.stepDescription}>
                                                {step.description}
                                            </Typography>
                                        </CardContent>
                                        <div className={classes.greenTickIcon}><GreenTickIcon /></div>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        <Typography className={classes.subTitle}>
                            My purpose is to always be helping people in their pursuits
                        </Typography>
                        <Typography className={classes.titlePara}>
                            Now you are ready to move to execute your plan
                        </Typography>
                    </MuiCard>

                    {/* <Card className={classes.card}>
                        <Typography variant="h5" align="center" gutterBottom>
                            Configure Your Program
                        </Typography>

                        <Typography variant="body1" className={classes.sectionHeader}>
                            Your Schedule
                        </Typography>
                        <Box>
                            {[
                                { label: 'Weekly Check-ins: Maintain balance', checked: true },
                                { label: 'Quarterly Retrospectives and Planning', checked: true },
                                { label: 'Annual Retrospectives and Planning', checked: true },
                                { label: 'Daily Check-ins', checked: false },
                                { label: 'Monthly Check-ins', checked: false },
                            ].map((item, index) => (
                                <Box className={classes.listItem} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox checked={item.checked} style={{ color: '#7CA0BB' }} />}
                                        label={item.label}
                                    />
                                    <Typography variant="body2" className={classes.previewLink}>
                                        Preview
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        <Typography variant="body1" className={classes.sectionHeader}>
                            Notification Settings
                        </Typography>
                        <Box>
                            {[
                                { label: 'Email', value: 'john@example.com', checked: true },
                                { label: 'SMS', value: '0123456789', checked: false },
                            ].map((item, index) => (
                                <Box className={classes.listItem} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox checked={item.checked} style={{ color: '#7CA0BB' }} />}
                                        label={item.label}
                                    />
                                    <Typography variant="body2" color="textSecondary">
                                        {item.value}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    </Card> */}

                    <ActionButtons
                        phaseNumber={3}
                        learnMoreText='Learn More'
                        nextText='Next: Establish Balance & Focus'
                        onLearnMore={() => console.log('Learn More clicked')}
                        onNext={() => onNext()}
                        backText='Back to Goal setting'
                        onBack={() => onBack()}
                        // isDisabledPrevious={phaseNumber === 3 ? true : false}
                        isDisabledNext={phaseNumber === 3 ? false : true}
                    />
                </div>

            </Box>
        </>
    )
}

export default OnboardingScreen3