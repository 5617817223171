import React from 'react';
import { confirmable } from 'react-confirm';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../theme';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: 44,
    },
    title: {
      fontWeight: `bold`,
      lineHeight: '24px',
      letterSpacing: '0.01em',
      textAlign: 'center',
    },
    content: {
      textAlign: 'center',
      color: '#3B3B3B',
    },
    actions: {
      justifyContent: 'center',
    },
    actionButton: {
      textTransform: 'none',
      flex: 1,
    },
    saveButton: {
      textTransform: 'none',
      flex: 1,
      backgroundColor: '#3F4148',
      color: 'white',
    },
  })
);

const ConfirmDialog = ({
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
  title,
  options,
  buttonOptions
}) => {
  const classes = useStyles();

  // Handle "Leave & Discard" separately
  const leaveAndDiscard = () => {
    proceed('leaveAndDiscard'); // Pass a specific value for this action
  };
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        onClose={dismiss}
        open={show}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle
          classes={{ root: classes.root }}
          id="confirm-dialog-title"
          disableTypography
        >
          <Typography variant="h3" className={classes.title}>
            <b>{title}</b>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className={classes.content}
            id="alert-dialog-description"
          >
            {confirmation}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.actions }}>
          <Button
            className={classes.saveButton}
            variant="contained"
            onClick={() => proceed('save')}
            color="primary"
            size="large"
            autoFocus
          >
            {buttonOptions.modaltype? "Yes":"Save"}
          </Button>
          <Button
            className={classes.actionButton}
            variant="outlined"
            onClick={() => cancel()}
            color="primary"
            size="large"
          >
            Cancel
          </Button>
          {!buttonOptions.modaltype &&<Button
            className={classes.actionButton}
            variant="outlined"
            onClick={leaveAndDiscard}
            color="primary"
            size="large"
          >
            Leave & Discard
          </Button>
          }
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);
